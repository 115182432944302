import moment from "moment";
import "../../assets/css/backgroundPhone.css";
import "../../assets/css/backgroundListNotify.css";
import Logo from "../../assets/fontawesome/image/logo_mvc.png";
import Gach from "../../assets/fontawesome/image/gach.png";
import SHARE_ICON from "../../assets/fontawesome/image/share-button.svg";
import "../../assets/css/backgroundButton.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import IconPhoneAndZalo from "../../component/IconPhoneAndZalo/IconPhoneAndZalo";
import "../../assets/css/font-text.css";
import { useEffect, useState } from "react";
import { campaignServices } from "../../services/apiService/campaignServices";

export default function HotdealDetails() {
  const [campaignDetail, setCampaignDetail] = useState(null);
  const { id } = useParams();
  const navigation = useNavigate();

  const handleBack = () => {
    navigation("/");
  };
  const getCampaignDetail = (id) => {
    campaignServices
      .getCampaignDetailApi(+id)
      .then((res) => {
        setCampaignDetail(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getCampaignDetail(id);
  }, []);
  const formatDate = (date = "") => {
    return moment(date).format("DD/MM/YYYY");
  };
  return (
    <div className="contain">
      <div className="bg-no-repeat h-full ml-[15px] mr-[15px] mt-[15px] min-h-[400px] max-[335px]:min-h-[310px] max-[380px]:min-h-[360px]">
        <img
          className="w-full h-full"
          src={campaignDetail?.campaign_banner}
          alt=""
        />
      </div>

      <div className="max-h-full px-[25px] mt-5 max-[410px]:mt-0">
        <div className="italic text-[#1D86FA] font-normal text-[13px] leading-4 font-italic-mon">
          <span>{formatDate(campaignDetail?.start_date)}</span> -{" "}
          <span>{formatDate(campaignDetail?.end_date)}</span>
        </div>
        <div className="flex justify-between">
          <div className="text-black font-bold-mon text-[20px] leading-5 w-[75%] mt-1">
            {campaignDetail?.name}
          </div>

          {/* <img src={SHARE_ICON} className="w-14 h-14 cursor-pointer" /> */}
        </div>
        <div className="mt-5 text-[13px] leading-[21px]">
          <div
            dangerouslySetInnerHTML={{
              __html: campaignDetail?.campaign_banner_detail,
            }}
          ></div>
        </div>
      </div>

      <div className="flex justify-center mb-[80px]">
        <Link
          className="color-button-primary my-[25px] text-white py-3 px-3.5 rounded-2xl cursor-pointer"
          to={`/bigc/guide-takeaphoto/${campaignDetail?.id}`}
        >
          Tham gia ngay
        </Link>
      </div>

      <div className=" fixed bottom-0 w-full flex justify-center items-center footer-background">
        <img src={Logo} />
        <img src={Gach} className="form__space" />
        <div className="form__footer-copyright font-regular-mon">
          Copyright © 2023{" "}
          <span className="form__footer-mvc">MVC Company.</span> All rights
          reserved.
        </div>
      </div>

      <IconPhoneAndZalo className="fixed" />
    </div>
  );
}
