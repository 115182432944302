import { useEffect, useState } from "react";
import "../../assets/css/listGift.css";
import HeaderBackground from "../../component/HeaderBackground/HeaderBackground";
import IconPhoneAndZalo from "../../component/IconPhoneAndZalo/IconPhoneAndZalo";
import QR_GIFT from "../../assets/fontawesome/image/qr_gift.svg";
import NavbarHome from "../../component/NavbarHome/NavbarHome";
import GiftItem from "./GiftItem";
import Footer from "../../component/Footer/Footer";
import BACKGROUND_CONTENT from "../../assets/fontawesome/image/background-content.png";
import { useSelector } from "react-redux";
import {
  axiosInstance,
  contenttype,
  setAuthorization,
} from "../../services/apiService/configURL";
import { userServices } from "../../services/apiService/userServices";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import Logo from "../../assets/fontawesome/image/logo_mvc.png";
import FooterHasNavbar from "../../component/Footer/FooterHasNavbar";
import { userDataLocal } from "../../services/localService/localService";
import { homeServices } from "../../services/apiService/homeServices";
import WARNING from "../../assets/fontawesome/image/warning.png";
import Loading from "../../component/Loading/Loading";
let buttonBack = "/";
const TITLE = "Quà của tôi";
export default function () {
  console.log(axiosInstance.defaults.headers.common["TOKEN"]);
  const [current, setCurrent] = useState("1");
  const { token } = userDataLocal.get();
  console.log(token);
  const [items, setItems] = useState();
  const [status, setStatus] = useState(false);
  const [statusGifts, setStatusGifts] = useState("done");

  const getMyListGift = (val) => {
    homeServices
      .getMyPrizeList(statusGifts)
      .then((res) => {
        console.log(res);
        setStatus(false);
        const resp = res.list_reward.filter((x) => x.campaign_id === 109);
        setItems(resp);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err);
      });
  };
  useEffect(() => {
    setStatus(true);
    setAuthorization(token);
    getMyListGift(true);
  }, []);
  return (
    <div>
      {status ? <Loading /> : null}
      <div>
        <img src={BACKGROUND_CONTENT} className="fixed" />
      </div>
      <div className="absolute top-0 w-full">
        {items?.length === 0 ? (
          <div className="relative">
            <div className="absolute top-40 left-1/2 -translate-x-1/2">
              <img src={WARNING} />
              <div className="text-center font-semibold-mon text-black mt-5">
                Hiện tại, bạn vẫn chưa có quà. Tham gia ngay để nhận ngay cơ hội
                săn quà siêu hấp dẫn.
              </div>
            </div>
          </div>
        ) : (
          <ul className="containerNotify__background-listnoti pt-7 box-border bottom-20 z-30 ">
            {items?.map((infor, index) => {
              return (
                <li
                  className="mx-[25px] bg-[#f0f0f0] rounded-xl mb-4 overflow-hidden"
                  key={index}
                >
                  <GiftItem infor={infor} />
                </li>
              );
            })}
          </ul>
        )}
        <div className="h-40"></div>
        <FooterHasNavbar />
      </div>

      <IconPhoneAndZalo />
      <NavbarHome />
    </div>
  );
}
