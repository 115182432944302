import { URL_API_BE_ODOO, axiosInstance } from "./configURL";

const HOST = window.location;
let CUSTOM_URL_SEND_WITH_API = "https://mm.mvc.com.vn/";
let BASE_URL_NODEJS = "https://pgocr.mvc.com.vn";
if (!HOST.hostname.includes("localhost")) {
  CUSTOM_URL_SEND_WITH_API = HOST.protocol + "//" + HOST.hostname + "/";
  BASE_URL_NODEJS = HOST.protocol + "//" + HOST.hostname;
}
let BASE_URL = "https://pgocr.mvc.com.vn/";
export const CAMPAIGN_APPCODE = "CAMPAIGN_APPCODE";
export const CAMPAIGN_INFO = "CAMPAIGN_INFO";
export const CAMPAIGN_PGCODE = "CAMPAIGN_PGCODE";
export const URL_SEND_WITH_API = CUSTOM_URL_SEND_WITH_API;
export const WHEEL_LUOTQUAY = "WHEEL_LUOTQUAY";
export const WHEEL_HAS_LUOTQUAY = "WHEEL_HAS_LUOTQUAY";
export const WHEEL_PHANTHUONG = "WHEEL_PHANTHUONG";
export const URL_API_BE_ODOO1 = BASE_URL;
export const URL_API_BE_GETCAMPAIGN = BASE_URL + "ocr/campaign_info";

export const luckyDrawService = {
  getLuckyDrawList: () => {
    return axiosInstance.post("/mega_market/api_get_lucky_draw_list", {});
  },
  postDrawLuckyGift: (data) => {
    return axiosInstance.post("/ocr/api_submit_fg_lucky_gift", {
      params: data,
    });
  },
  countDraw: (data) => {
    return axiosInstance.post("/ocr/count_draw", {
      params: data,
    });
  },
  postCountDraw: (soID) => {
    return axiosInstance.post("/bud/count_draw", {
      params: {
        so_id: soID,
        count: 1,
      },
    });
  },
};
