import NavbarHome from "../../component/NavbarHome/NavbarHome";
import ICON_HISTORY_PARTICI from "../../assets/fontawesome/image/icon-history-participate.svg";
import ICON_MYGIFT from "../../assets/fontawesome/image/icon-mygift.svg";
import "../../assets/css/inforCustomer.css";
import ICON_RIGHT from "../../assets/fontawesome/image/icon-right.svg";
import IconPhoneAndZalo from "../../component/IconPhoneAndZalo/IconPhoneAndZalo";
import BACKGROUND_CONTENT from "../../assets/fontawesome/image/background-content.png";

import { useNavigate } from "react-router-dom";
import FooterHasNavbar from "../../component/Footer/FooterHasNavbar";
const TITLE = "Thông tin khách hàng";
const clickFilter = "/list-notify";
export default function InforCustomer() {
  const navigation = useNavigate();

  const handleMyGift = () => {
    navigation("/wilmar/list-gift");
  };
  const handleHistory = () => {
    navigation("/wilmar/participate-history");
  };

  return (
    <div>
      <div>
        <img src={BACKGROUND_CONTENT} className="fixed" />
      </div>
      <div className="absolute top-0 left-0 w-full ">
        <ul className="bg-[#fff] mt-8 mx-6 box-border z-20 grid-rows-2 border-2 rounded-lg">
          <li className="content-info-li border-b-2" onClick={handleMyGift}>
            <div className="icon-infor-li">
              <img src={ICON_MYGIFT} />
            </div>
            <div>Quà của tôi</div>
            <div className="ml-auto mr-6">
              <img src={ICON_RIGHT} />
            </div>
          </li>
          <li className="content-info-li " onClick={handleHistory}>
            <div className="icon-infor-li">
              <img src={ICON_HISTORY_PARTICI} />
            </div>
            <div>Lịch sử tham gia</div>
            <div className="ml-auto mr-6">
              <img src={ICON_RIGHT} />
            </div>
          </li>
        </ul>
      </div>
      <FooterHasNavbar />
      <IconPhoneAndZalo />
      <NavbarHome />
    </div>
  );
}
