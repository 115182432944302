import "../../assets/css/backgroundListNotify.css";
import "../../assets/css/prizeRules.css";
import IconPhoneAndZalo from "../../component/IconPhoneAndZalo/IconPhoneAndZalo";
import { useEffect, useState } from "react";
import { campaignServices } from "../../services/apiService/campaignServices";
import BACKGROUND_CONTENT from "../../assets/fontawesome/image/background-content.png";
import { useNavigate } from "react-router-dom";
import FooterHasNavbar from "../../component/Footer/FooterHasNavbar";

export default function PrizeRules() {
  const [prizeTerm, setPrizeTerm] = useState(null);
  let LINK_HOMES = JSON.parse(localStorage.getItem("WILMAR_HOME"));
  const navigate = useNavigate();
  useEffect(() => {
    campaignServices
      .getPrizeTerm()
      .then((res) => {
        setPrizeTerm(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const handleBack = () => {
    navigate("/wilmar" + LINK_HOMES);
  };
  return (
    <div>
      <div>
        <img src={BACKGROUND_CONTENT} className="fixed" />
      </div>
      <div className=" absolute rounded-[30px_30px_0_0] bottom-0 h-[98%] w-full z-10">
        <div className="containerNotify__background-list font-light-mon box-border z-20">
          <div className=" box-border z-20 mx-6">
            <div className="font-semibold-mon leading-4 text-[13px] mt-3">
              <div className="font-bold-mon uppercase text-[#E7212F]">
                {/* QUÀ TẶNG TƯNG BỪNG – VUI MỪNG ĐẠI LỄ */}
              </div>
              <div className="font-bold-mon uppercase text-[#E7212F] mt-4">
                <i class="fa-solid fa-star"></i> thể lệ chung
              </div>
              <ul className="font-light-mon text-[12px] leading-[21px] corlor-text-black">
                <li>
                  <span>
                    - Tên chương trình:{" "}
                    <span className="font-bold-mon text-[#16499F]">
                      MUA HÀNG WILMAR – TRÚNG QUÀ THẢ GA!
                    </span>
                  </span>
                </li>
                <li>
                  <span>
                    - Thời gian khuyến mãi: từ ngày{" "}
                    <span className="font-bold-mon">11/07/2024 </span>
                    đến hết ngày{" "}
                    <span className="font-bold-mon">24/07/2024</span>
                  </span>
                </li>
                <li>
                  - Khách hàng mua hàng trực tiếp tại các trung tâm{" "}
                  <span className="font-bold-mon text-[#16499F]">
                    MM Mega Market trên toàn quốc
                  </span>{" "}
                  trong hoá đơn có các sản phẩm của Wilmar (theo danh sách đính
                  kèm, không áp dụng với các sản phẩm gạo, có bao gồm Homecare)
                  với giá trị mỗi hoá đơn:
                </li>
                <li>
                  o   Hóa đơn trị giá từ{" "}
                  <span className="font-bold-mon text-[#16499F]">249.000đ</span>{" "}
                  đến dưới{" "}
                  <span className="font-bold-mon text-[#16499F]">499.000đ</span>{" "}
                  các{" "}
                  <span className="font-bold-mon text-[#333333]">
                    sản phẩm Wilmar
                  </span>{" "}
                  sẽ nhận được{" "}
                  <span className="font-bold-mon text-[#333333]">
                    “01 lượt”
                  </span>{" "}
                  tham gia vòng quay trúng thưởng.
                </li>
                <li>
                  o   Hoá đơn trị giá từ{" "}
                  <span className="font-bold-mon text-[#16499F]">499.000đ</span>{" "}
                  các sản phẩm Wilmar sẽ nhận được{" "}
                  <span className="font-bold-mon text-[#333333]">
                    “02 lượt”
                  </span>{" "}
                  tham gia vòng quay trúng thưởng.
                </li>
                <li>
                  o   Giá trị mua hàng theo điều kiện chương trình đã bao gồm
                  VAT, sau chiết khấu trực tiếp Sản Phẩm và không bao gồm chiết
                  khấu{" "}
                  <span className="font-bold-mon text-[#333333]">
                    MM Mega Market
                  </span>{" "}
                  triển khai áp dụng cho Sản Phẩm.
                </li>

                <li className="font-bold-mon uppercase text-[#E7212F]">
                  <i class="fa-solid fa-star"></i> Lưu ý:
                </li>
                <li className="font-bold-mon text-[#333333]">
                  - KHÁCH HÀNG THAM GIA CHƯƠNG TRÌNH ĐỔI QUÀ PHẢI SỬ DỤNG app
                  MCard và ĐĂNG KÍ THÀNH VIÊN.
                </li>
                <li>
                  - Mỗi Khách hàng được quay thưởng tối đa 2 lượt/ 1 ngày, tương
                  đương tối đa 2 lượt quay/ hoá đơn
                </li>
                <li>- Mỗi hóa đơn chỉ được quét để quay thưởng 1 lần.</li>
                <li>
                  - Lượt chơi chỉ được áp dung trên hoá đơn riêng lẻ, không áp
                  dụng cộng gộp hoá đơn
                </li>
                <li>
                  - Hoá đơn và cả lượt chơi của hoá đơn đó chỉ có hiệu lực trong
                  vòng 72 tiếng kể từ lúc xuất hoá đơn.
                </li>
                <li>
                  - Giá trị sản phẩm, hóa đơn tham gia chương trình được tính
                  theo giá sản phẩm đã trừ khuyến mãi, chiết khấu thương mại.
                </li>
                <li>
                  - Hóa đơn hợp lệ để tham gia chương trình là hóa đơn có mua
                  sản phẩm được phát hành trong thời gian diễn ra chương trình
                  được thông báo trên các phương tiện truyền thông và vật phẩm
                  được trưng bày tại siêu thị.
                </li>
                <li>
                  - Hóa đơn hợp lệ là hóa đơn gốc của siêu thị (Không áp dụng
                  cho hóa đơn in lại và hóa đơn photo).
                </li>
                <li>
                  ***
                  <span className="font-bold-mon text-[#333333]">
                    Đối với giải thưởng là điểm MCard:
                  </span>{" "}
                  giải thưởng sẽ có hạn sử dung như sau:
                </li>
                <li>
                  o  {" "}
                  <span className="font-bold-mon text-[#333333]">
                    Khách hàng Cá nhân:
                  </span>{" "}
                  được{" "}
                  <span className="font-bold-mon text-[#16499F]">
                    12 tháng sử dụng
                  </span>{" "}
                  kể từ ngày nhận điểm thưởng
                </li>
                <li>
                  o  {" "}
                  <span className="font-bold-mon text-[#333333]">
                    Khách hàng Doanh nghiệp:
                  </span>{" "}
                  được{" "}
                  <span className="font-bold-mon text-[#16499F]">
                    06 tháng sử dụng
                  </span>{" "}
                  kể từ ngày nhận điểm thưởng
                </li>
                <li>
                  <span className="font-bold-mon text-[#333333]">
                    ***Đối với giải thưởng là Máy giặt:
                  </span>{" "}
                  Khách hàng sẽ được Máy giặt hoặc Voucher UrBox mua Máy giặt
                  tại hệ thống siêu thị Điện máy xanh trên toàn quốc có trị giá
                  tương đương. Bộ phận chăm sóc Khách hàng sẽ tự động liên hệ
                  Khách hàng theo thông tin đã đăng kí trên app MCard để xác
                  nhận thông tin và hướng dẫn nhận thưởng trong vòng 45 ngày kể
                  từ ngày quay trúng thưởng.
                </li>
                <li>
                  <span className="font-bold-mon text-[#333333]">
                    {" "}
                    ***Đối với giải thưởng là 1 năm sử dụng Dầu gạo lứt nhãn
                    hiệu Simply 1L
                  </span>{" "}
                  (tương đương 12 chai): Khách hàng sẽ được nhận trưc tiếp tại
                  trung tâm MM Mega Market. Bộ phận chăm sóc Khách hàng sẽ tự
                  động liên hệ Khách hàng theo thông tin đã đăng kí trên app
                  MCard để xác nhận thông tin và hướng dẫn nhận thưởng trong
                  vòng 45 ngày kể từ ngày quay trúng thưởng.
                </li>
                <li>- Giải thưởng không có giá trị quy đổi thành tiền mặt.</li>
                <li>
                  - Thời gian trả thưởng cho Khách hàng chậm nhất là 45 ngày kể
                  từ ngày kết thúc chương trình.
                </li>
                <li>
                  - Chương trình có thể kết thúc sớm hơn dự kiến do số lượng quà
                  tặng có hạn.
                </li>
                <li>
                  <span className="font-bold-mon text-[#333333]">
                    {" "}
                    ***Thủ tục trao đổi thưởng:
                  </span>{" "}
                  Khách hàng phải cung cấp Hoá đơn và hình ảnh kết quả trúng
                  thưởng trên ứng dụng MCard với đầy đủ thông tin người trúng
                  thưởng (CMND/ CCCD/ Hộ chiếu) trùng khớp với dữ liệu mà hệ
                  thống đã lưu trữ. Trường hợp hoá đơn hoặc hình ảnh thông báo
                  trúng thưởng bị mất nhưng Khách hàng chứng minh được là người
                  trúng thưởng (xác nhận thông tin CMND/ số hoá đơn/….) và khách
                  sẽ ghi thêm giấy cam kết xác nhận bị mất và ký tên vào biên
                  bản trao giải.
                </li>
                <li>
                  {" "}
                  <span className="font-bold-mon text-[#333333]">
                    - Thời hạn kết thúc trao thưởng: 45 ngày sau khi kết thúc
                    chương trình
                  </span>
                </li>
                <li>
                  <span className="font-bold-mon text-[#333333]">
                    - Trách nhiệm Khách hàng trúng thưởng:
                  </span>{" "}
                  đối với chi phí phát sinh khi nhận thưởng, thuế thu nhập không
                  thường xuyên; Khách hàng trúng giải phải tự chịu tất cả các
                  chi phí liên quan đến việc nhận thưởng như chi phí đi lại, ăn
                  ở và có nghĩa vụ nộp thuế thu nhập cá nhân cho Công Ty TNHH MM
                  Mega Market (Việt Nam) thay mặt người trúng thưởng nộp cho cơ
                  quan thuế theo qui định của Pháp luật.
                </li>
                <li>
                  - Chương trình quay thưởng có thể kết thúc sớm nếu số lượng
                  quà tặng đã triển khai hết.
                </li>
                <li className="font-bold-mon text-[#333333]">
                  ***Mọi thắc mắc về chương trình Khách hàng liên hệ qua
                  Hotline:
                  <span className="text-[#E7212F] font-bold-mon">
                    {" "}
                    1800 646 878
                  </span>{" "}
                  . Hotline hoạt động từ{" "}
                  <span className="text-[#E7212F] font-bold-mon">
                    {" "}
                    08:30 – 19:00
                  </span>{" "}
                  tất cả các ngày trong tuần (cả ngày Lễ, Tết).
                </li>
              </ul>
            </div>
          </div>
          <div className="flex justify-center py-[50px] box-border font-light-mon">
            <button
              onClick={handleBack}
              className="btn-prizerules color-button-primary corlor-text-darkwhite px-[32px]
               py-[15px] text-center rounded-xl "
            >
              Trở lại
            </button>
          </div>
        </div>
        <IconPhoneAndZalo />
      </div>
      <FooterHasNavbar />
    </div>
  );
}
