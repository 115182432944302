import axios from "axios";
import { URL_API_BE_ODOO, axiosInstance, campaignURL } from "./configURL";

export const homeServices = {
  getCampaignInfo: () => {
    return axiosInstance.get("/bud/campaign_info", {
      params: {
        url: campaignURL,
      },
    });
  },
  getMyPrizeList: (statusGifts) => {
    return axiosInstance.post(
      `/mega_market/get_my_reward`,
      {},
      {
        headers: "Content-Type: application/json",
      }
    );
  },
  postLogin: (data) => {
    return axiosInstance.post(
      "/mega_market/login",
      {
        params: data,
      },
      {
        headers: "Content-Type: application/json",
      }
    );
  },
  getRunningCampaignAll: () => {
    return axiosInstance.post("/bud/get_all_campaign", {
      headers: "Content-Type: application/json",
    });
  },
  getCampaignClip: () => {
    return axiosInstance.post("/bud/get_homepage_clip", {
      headers: "Content-Type: application/json",
    });
  },
  getRunningCampaignTopAndDown: () => {
    return axiosInstance.post("/bud/get_homepage_banner", {
      headers: "Content-Type: application/json",
    });
  },
  getRunningCampaign: () => {
    return axiosInstance.post("/bud/get_running_campaign", {
      headers: "Content-Type: application/json",
    });
  },
  getHomepageBannerApi: () => {
    return axiosInstance.post(
      "/bud/get_homepage_banner",
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },
};

export class apiOdoo {
  static async getData(url) {
    return await axios.get(url);
  }
  static async postLogin(url, params) {
    var config = {
      method: "POST",
      url: url,
      params: params,

      headers: {
        "Content-Type": "application/json",
      },
    };
    return await axios(config)
      .then(function (response) {
        console.log(response);
        return response;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  static async postData(url, data, headers) {
    var config = {
      method: "POST",
      url: url,
      headers: headers,
      data: data,
      timeout: 90000,
    };
    return await axios(config)
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
}
