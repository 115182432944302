import { useEffect } from "react";
import IconZalo from "../../assets/fontawesome/image/logo_zalo.png";
import {
  CAMPAIGN_INFO,
  URL_API_BE_GETCAMPAIGN,
  URL_SEND_WITH_API,
} from "../../services/apiService/LuckyDraw";
import { apiOdoo } from "../../services/apiService/homeServices";
let contact = "1800799998";
export default function IconPhoneAndZalo() {
  let webviewCallbackReady = false;
  let appCode = window.location.pathname.split("/")[1];

  let cpData = JSON.parse(localStorage.getItem(CAMPAIGN_INFO) || "{}");
  useEffect(() => {
    apiOdoo
      .getData(
        `${URL_API_BE_GETCAMPAIGN}?1=1&url=${URL_SEND_WITH_API}${appCode}`
      )
      .then((res) => {
        cpData = res.data.result.data;
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  useEffect(() => {
    window.addEventListener(
      "flutterInAppWebViewPlatformReady",
      function (event) {
        webviewCallbackReady = true;
      }
    );
  });
  function open_launcher(param) {
    window.flutter_inappwebview.callHandler("launchApp", param);
  }

  return (
    <div className="fixed bottom-36 right-3 z-20 w-[62px] flex flex-wrap justify-center max-[376px]:bottom-[100px]">
      <a
        onClick={(e) => open_launcher(`tel:${cpData?.hotline}`)}
        className="container__contact-phonebgr flex justify-center items-center w-[53px] h-[70px] cursor-pointer"
      ></a>
      <a
        className="container__contact-zalo text-center w-[65px] h-[65px] z-40 -mt-[10px] cursor-pointer"
        onClick={(e) => open_launcher("https://m.me/MMMegaMarketVietnam")}
      ></a>
    </div>
  );
}
