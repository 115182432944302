import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useParams } from "react-router-dom";
import { setAuthorization } from "../../services/apiService/configURL";
import { userDataLocal } from "../../services/localService/localService";

export default function ProtectedRoute({ children }) {
  let { token } = userDataLocal.get();
  console.log(token);
  if (!token) {
    return <Navigate to={"/wilmar/detectapp"} replace />;
  } else {
    setAuthorization(token);
    return <div>{children ? children : <Outlet />}</div>;
  }
}
