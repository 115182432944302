import "../../assets/css/popupPhone.css";
import Image from "../../assets/fontawesome/image/cancel-phone-icon.png";
import ICON_CANCEL from "../../assets/fontawesome/image/icon-cancel.png";
import { userServices } from "../../services/apiService/userServices";
import {
  provinceLocal,
  userDataLocal,
} from "../../services/localService/localService";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { setUserData } from "../../Redux/Action/userAction";
import { setAuthorization } from "../../services/apiService/configURL";
import { setProvince } from "../../Redux/Action/userAction";
import Modal from "react-modal";

export default function ConfirmPopupPhone({
  setPopup,
  popup,
  updateInfoDone = (res) => {},
}) {
  const dispatch = useDispatch();
  const phone = localStorage.getItem("phoneData");
  const name = localStorage.getItem("nameData");

  const data = {
    phone: phone,
    name: name,
  };

  const handleClick = () => {
    setPopup(false);
  };
  const onSubmit = () => {
    userServices
      .postUserLoginNoRegister(data)
      .then((res) => {
        console.log(res);
        userDataLocal.set(res);
        dispatch(setUserData(res));
        setPopup(false);
        setAuthorization(res.token);
        // provinceLocal.set(res);
        // dispatch(setProvince(res))
        // toast.success('Thành công');
        updateInfoDone(res);
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => {
        console.log("finally");
      });
  };
  return (
    <Modal
      isOpen={popup}
      // style={customStyles}isUpload
      contentLabel="Example Modal"
      ariaHideApp={false}
      className="z-50"
    >
      {/* <div className='z-50'> */}
      <div className="popup-confirm-phone absolute top-[25%] mx-4 h-[70px] z-50 ">
        <div className="mt-[40px] rounded-[30px] bg-white border-2 border-solid w-full z-50">
          <img
            src={Image}
            className="image__phone relative top-0 left-1/2 z-50"
          />

          <div className="flex flex-col justify-center items-center z-50">
            <div className="-mt-8 text-[24px] text-[#F63440] z-50">
              THÔNG BÁO
            </div>
            <div className="text-[16px] text-[#F63440] z-50">{phone}</div>

            <div className="text-[13px] px-16 text-center z-50">
              Có phải số điện thoại chính xác của bạn? Chúng tôi sẽ gửi quà đến
              số điện thoại này.
            </div>

            <div className="flex justify-center py-[30px] box-border text-[#333] font-light-mon">
              <input
                onClick={onSubmit}
                className="color-button-primary border-0 text-[#fff] px-[32px] py-[17px] text-center no-underline inline-block rounded-3xl text-[16px] cursor-pointer font-black leading-5"
                value="Đồng ý"
              />
            </div>

            <img
              src={ICON_CANCEL}
              alt=""
              className=" mb-4"
              onClick={() => handleClick()}
            />
          </div>
        </div>
      </div>
      {/* </div> */}
    </Modal>
  );
}
