import BACKGROUND_CONTENT from "../../assets/fontawesome/image/background-content.png";

export default function DetectWilmar() {
  const handleGG = () => {
    window.location =
      "https://play.google.com/store/apps/details?id=com.megamarket&hl=vi&gl=US";
  };
  const handleApp = () => {
    window.location =
      "https://apps.apple.com/us/app/mcard-by-mm-mega-market/id1492800470";
  };
  return (
    <div>
      <div className="relative">
        <img src={BACKGROUND_CONTENT} className="" />
        <div className="absolute  detect-bg-white bg-white w-9/12 rounded-xl h-32">
          <div className="flex justify-center items-center ">
            <div className=" text-left px-3 pt-5 font-regular-mon">
              Vui lòng tải, update app MCard trên{" "}
              <span className="text-green-600 font-bold-mon" onClick={handleGG}>
                Google Play
              </span>{" "}
              hoặc{" "}
              <span
                className="text-green-600 font-bold-mon"
                onClick={handleApp}
              >
                App Store
              </span>{" "}
              để truy cập vào web.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
