import React, { useEffect, useState } from "react";
import logo_png from "../../assets/fontawesome/image/logo_png.svg";
import gold_prize from "../../assets/fontawesome/image/gold-prize.png";
import "./AnnouncePrize.css";
import IconPhoneAndZalo from "../../component/IconPhoneAndZalo/IconPhoneAndZalo";
import { NavLink, useNavigate } from "react-router-dom";
const style = {
  // backgroundColor: '#47CCFF',
  color: "#130682",
  fontSize: "16px",
  fontHeight: "20px",
  fontWeight: "600",
};

export default function AnnouncePrize({
  handleChangeAnnounceStatus,
  handleEnableBtn,
}) {
  const winningGift = JSON.parse(localStorage.getItem("WINNING_GIFT"));
  const [isShorten, setIsShorten] = useState(true);
  const navigate = useNavigate();
  const handleShortingText = () => {
    setIsShorten(!isShorten);
  };
  const handleNavigateSucess = () => {
    navigate("/wilmar/wheel-success");
  };

  return (
    <>
      <div className="">
        <div className="annouce-prize text-[#FFFFFF] contain">
          <div className="pt-[20px]">
            <div className="flex justify-center flex-col items-center">
              <div>
                <img className="" src={logo_png} alt="" />
              </div>
              <div className="text-center">
                <h1 className="uppercase my-[26px] text-[28px] corlor-text-darkblue">
                  chúc mừng
                </h1>
                <p className="font-regular-mon text-[22px] text-[#333]">
                  Bạn đã nhận được
                </p>
                <span className="font-bold-mon text-[22px] text-[#333]">
                  {winningGift?.gift}
                </span>
              </div>
              <div className="mt-[31px]">
                <img
                  className="px-[30px]"
                  src={winningGift?.gift_image}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="font-light-mon text-[#333] mx-auto px-[39px] mt-[25px] text-[12px]">
            <p>
              Đối với Giải thưởng là Thẻ quà tặng: Quí khách sẽ được gửi qua tin
              nhắn vào SĐT có kèm đường link voucher trong vòng 1 tiếng.
              <span
                className={`text-[#EFDB8E] cursor-pointer ${
                  isShorten ? "inline-block" : "hidden"
                } ml-1`}
                onClick={() => {
                  handleShortingText();
                }}
              >
                Xem thêm...
              </span>
            </p>
            <p className={`${isShorten ? "hidden" : "block"}`}>
              Đối với Giải thưởng là vàng hoặc quà hiện vật: Bộ phận CSKH sẽ
              liên hệ với Khách hàng để thông báo và xin thông tin địa chỉ và
              tiến hành giao hàng tận nhà cho khách hàng. Quà tặng sẽ được trao
              trong vòng 15 - 30 ngày kể từ ngày kết thúc Chương trình. Vui lòng
              giữ tin nhắn SMS thông báo trúng giải để nhận thưởng.{" "}
              <span
                className={`text-[#EFDB8E] cursor-pointer `}
                onClick={() => {
                  handleShortingText();
                }}
              >
                Rút gọn...
              </span>
            </p>
          </div>
          <div className=" flex justify-center py-[50px] box-border text-[#333] font-light-mon">
            <button
              onClick={() => {
                if (winningGift?.spinRemain >= 1) {
                  handleChangeAnnounceStatus();
                  handleEnableBtn();
                } else {
                  handleNavigateSucess();
                }
              }}
              style={style}
              type="submit"
              className="bg-[#F2E02B] border-0 text-[#333] px-[32px] py-[15px] text-center no-underline inline-block rounded-xl text-[16px] cursor-pointer not-italic font-[Montserrat-Light] font-black leading-5"
            >
              {winningGift?.spinRemain >= 1
                ? `Quay tiếp (còn ${winningGift.spinRemain} lượt quay)`
                : "Nhận quà ngay"}
            </button>
          </div>
        </div>
        <IconPhoneAndZalo />
      </div>
    </>
  );
}
