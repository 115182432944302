import "../../assets/css/Home.css";
import "../../assets/css/background__Footer.css";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../../assets/css/font-text.css";
import NavbarHome from "../../component/NavbarHome/NavbarHome";
import { apiOdoo, homeServices } from "../../services/apiService/homeServices";
import { useDispatch } from "react-redux";
import FooterHasNavbar from "../../component/Footer/FooterHasNavbar";
import IconPhoneAndZalo from "../../component/IconPhoneAndZalo/IconPhoneAndZalo";
import KIVI_TOP from "../../assets/fontawesome/image/kivi-mmtop.jpg";
import KIVI_BOTTOM from "../../assets/fontawesome/image/kivi-mmbottom.jpg";
import {
  USER_DATA_LOCAL,
  userDataLocal,
} from "../../services/localService/localService";
import md5 from "js-md5";
import {
  CAMPAIGN_INFO,
  URL_API_BE_GETCAMPAIGN,
  URL_SEND_WITH_API,
} from "../../services/apiService/LuckyDraw";
import { setAuthorization } from "../../services/apiService/configURL";
import { setUserData } from "../../Redux/Action/userAction";
import queryString from "query-string";
import ConfirmPopupBtnHorizontal from "../../component/ConfirmPopupBtnHorizontal/ConfirmPopupBtnHorizontal";
import Loading from "../../component/Loading/Loading";

const KEY = "9382yfhhj320f9782fuj293f782i3uf2ayd7gw843u9";

export default function Home() {
  const a = process.env.REACT_APP_API_KEY;
  console.log(a);
  const { token } = JSON.parse(localStorage.getItem(USER_DATA_LOCAL) || "{}");
  console.log(token);
  document.body.style.backgroundColor = "white";
  let appCode = window.location.pathname.split("/")[1];
  console.log(appCode);
  const location = useLocation();
  console.log(location);
  const dispatch = useDispatch();
  let cpData = JSON.parse(localStorage.getItem(CAMPAIGN_INFO) || "{}");
  const [loading, setLoading] = useState(false);
  const [popUpVersion, setPopupVersion] = useState(false);
  const navigation = useNavigate();
  const getCampaignOCR = () => {
    apiOdoo
      .getData(
        `${URL_API_BE_GETCAMPAIGN}?1=1&url=${URL_SEND_WITH_API}${appCode}`
      )
      .then((res) => {
        console.log(res);
        localStorage.setItem(
          CAMPAIGN_INFO,
          JSON.stringify(res.data.result.data)
        );
        cpData = res.data.result.data;
        console.log(cpData);
        // setloading(false);
        if (res.data.result.meta.status === false) {
          navigation("/notfound");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const getTokenInUrl = (cus_phone, cus_name, cus_no, customer_no_mm) => {
    const data = {
      name: cus_name,
      phone: cus_phone,
      mcard_id: cus_no,
      customer_no_mm: customer_no_mm,
    };
    console.log(data);

    homeServices
      .postLogin(data)
      .then((res) => {
        console.log(res);
        userDataLocal.set(res);
        setAuthorization(res.token);
        dispatch(setUserData(res));
        console.log("test");
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        // setLoading(false);
      });
  };
  useEffect(() => {
    if (!token) {
      setLoading(true);
      getCampaignOCR();
      window.scrollTo(0, 0);
      if (location.search === "") {
        navigation("/wilmar/detect");
      } else {
        localStorage.setItem("WILMAR_HOME", JSON.stringify(location.search));
        console.log(location.search);
        const a = queryString.parse(location?.search);
        let cus_no = a.customer_no;
        let customer_no_mm = a.customer_no_mm;
        const cus_store = a.customer_store;
        const cus_name = a.customer_name;
        const cus_phone = a.phone;
        const hash_wilmar = a.hash;
        const mcard_version = a.mcard_version.split(" ")[1];
        console.log(parseInt(mcard_version));
        const hashFE = md5(`${KEY}|${cus_no}|${cus_store}|${cus_phone}`);
        console.log("Hash FE: ", hashFE);
        console.log("Hash wilmar: ", hash_wilmar);
        if (parseInt(mcard_version) >= 180) {
          if (cus_phone !== undefined) {
            getTokenInUrl(cus_phone, cus_name, cus_no, customer_no_mm);
          }
          if (hashFE === hash_wilmar) {
            if (cus_no === undefined) {
              cus_no = "undefined";
            } else if (cus_no === null) {
              cus_no = "null";
            } else if (cus_no === "") {
              cus_no = "empty";
            }
            const data = {
              customer_no_mm: customer_no_mm,
              cus_no: cus_no,
              cus_name: cus_name,
              cus_store: cus_store,
              cus_phone: cus_phone,
              hash: hash_wilmar,
            };
            localStorage.setItem("infor_user", JSON.stringify(data));
            console.log(data);
          } else {
            navigation("/wilmar/detect");
          }
        } else {
          setPopupVersion(true);
        }
      }
      // getRunningCampaign();
    }
  }, []);
  const [isShowModalImg, setIsShowModalImg] = useState(false);
  const handleAnimationToPlay = () => {
    // setIsShowModalImg(true);
  };
  const handleTC = () => {
    navigation(`/wilmar/prize-rules`);
  };
  const handlePopupErrorOk = () => {
    window.flutter_inappwebview.callHandler("close_webview", null);
  };
  const handlePopupErrorOkImg = () => {
    localStorage.setItem("linkRedirect", "/wilmar/guide-takeaphoto");
    navigation(`/wilmar/guide-takeaphoto`);
  };
  return (
    <>
      {popUpVersion ? (
        <ConfirmPopupBtnHorizontal
          isShow={popUpVersion}
          labelOk="Đồng ý"
          message={"Vui lòng cập nhật ứng dụng MCard để tham gia chương trình"}
          handleOk={handlePopupErrorOk}
        />
      ) : null}
      {popUpVersion ? null : (
        <>
          <div className="background-yomost">
            <div className="">
              <div className="relative">
                <img src={KIVI_TOP} className="" />
              </div>
              <div className="relative z-0">
                <div className="h-16 text-white text-[16px] absolute z-10 top-2 w-72 btn-kivi rounded-2xl">
                  <button
                    className=" bg-[#16499F] p-4 absolute z-50 top-1 btn-tg w-[278px] rounded-xl"
                    onClick={handleAnimationToPlay}
                  >
                    Tham gia ngay
                  </button>
                </div>
                <div className=" h-16 text-white text-[16px] absolute top-[82px] z-10  w-72 btn-kivi-tg rounded-2xl">
                  <a
                    className=" bg-[#FDD137] text-black text-center p-4 absolute top-1 btn-tg w-[278px] rounded-xl"
                    onClick={handleTC}
                  >
                    Thể lệ chương trình
                  </a>
                </div>
                <img src={KIVI_BOTTOM} className="relative -top-1 z-0" />
              </div>
            </div>
            <div className="h-16"></div>
          </div>
          <FooterHasNavbar />
          <NavbarHome />
        </>
      )}
      <ConfirmPopupBtnHorizontal
        isShow={isShowModalImg}
        checkNotErr={true}
        labelOk="Đồng ý"
        message={
          "Mỗi Khách hàng được quay thưởng tối đa 2 lượt/ 1 ngày, tương đương tối đa 2 lượt quay/ hoá đơn."
        }
        handleOk={handlePopupErrorOkImg}
      />
      <IconPhoneAndZalo />
      {loading ? <Loading /> : null}
    </>
  );
}
