import { useState } from "react";

import "../../assets/css/font-text.css";
import BTN_TC from "../../assets/fontawesome/image/btn-tc.svg";
import MOBIPH from "../../assets/fontawesome/image/mobifone.png";

export default function GiftItem({ infor }) {
  const [status, setStatus] = useState(false);
  const [tc, setTC] = useState();
  const handleToggle = () => {
    setStatus(!status);
  };

  const handleClickTC = () => {
    setTC();
  };

  return (
    <>
      <div className="flex relative h-[92px] pl-[14px]">
        {/* <div> */}
        <img className="max-w-[76px] max-h-[60px] pb-4" src={infor.image} />
        {/* </div> */}
        <div>
          <div className="ml-2">
            <div className="text-cut-notify-title text-[#363534] text-[12px]">
              {infor.title}
            </div>
            <p className="text-cut-notify font-light-mon text-[12px]">
              {infor.gift_name}
            </p>
          </div>
          <div
            className="absolute bottom-0 font-bold-mon right-0 btn-tc-detail"
            onClick={handleClickTC}
          >
            <a href={infor?.tc_url} target="_blank" className="flex">
              Xem Thể Lệ <img src={BTN_TC} className="ml-2 w-4" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
