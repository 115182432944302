import { Carousel } from "react-responsive-carousel";
import ICON_DOTS from "../../assets/fontawesome/image/icon-dots.svg";
import ICON_DOTS_PRIMARY from "../../assets/fontawesome/image/icon-dots-primary.svg";
import BTN_NEXT_GUIDE from "../../assets/fontawesome/image/btn-cancel-guide.svg";
import B1_TAKEAPHOTO from "../../assets/fontawesome/image/b1-takephoto.png";
import B2_TAKEAPHOTO from "../../assets/fontawesome/image/b2-takephoto.png";
import B3_TAKEAPHOTO from "../../assets/fontawesome/image/b3-takephoto.png";
import B4_TAKEAPHOTO from "../../assets/fontawesome/image/b4-takephoto.png";
import B5_TAKEAPHOTO from "../../assets/fontawesome/image/b5-takephoto.png";
import CarouselTakeAPhoto from "./CarouselTakeAPhoto";
import BACKGROUND_CONTENT from "../../assets/fontawesome/image/background-content.png";
import ICON_EXPLAIN from "../../assets/fontawesome/image/icon-explain.svg";
import { NavLink } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "./GuideTakePhoto.css";
import { userDataLocal } from "../../services/localService/localService";
import { CAMPAIGN_INFO } from "../../services/apiService/LuckyDraw";
import CAM from "../CAM/CAM";
import { setAuthorization } from "../../services/apiService/configURL";
import FooterHasNavbar from "../../component/Footer/FooterHasNavbar";

export default function GuideTakeAPhoto() {
  let LINK_HOMES = JSON.parse(localStorage.getItem("WILMAR_HOME"));
  let link_home = LINK_HOMES.LINK_HOME;
  console.log(link_home);
  let cpData = JSON.parse(localStorage.getItem(CAMPAIGN_INFO) || "{}");
  console.log(cpData);
  const [imageFile, setimageFile] = useState(undefined);
  const [isUpload, setIsUpload] = useState(false);
  const data_wilmar = JSON.parse(localStorage.getItem("WILMAR_HOME"));
  let { token } = userDataLocal.get();
  setAuthorization(token);
  console.log(token);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const images = [
    {
      id: 1,
      url: B1_TAKEAPHOTO,
      title: "Thấy rõ tên siêu thị và ngày mua hàng",
    },
    {
      id: 2,
      url: B2_TAKEAPHOTO,
      title: "Thấy rõ tên và sản phẩm",
    },
    {
      id: 3,
      url: B3_TAKEAPHOTO,
      title: "Thấy rõ số hóa đơn",
    },
    {
      id: 4,
      url: B4_TAKEAPHOTO,
      title: "Không thấy rõ tên, sản phẩm,ngày, số hóa đơn",
    },
    {
      id: 5,
      url: B5_TAKEAPHOTO,
      title: "Hóa đơn đã bị chỉnh sửa(photoshop)",
    },
  ];

  useEffect(() => {
    console.log(12);
    window.addEventListener(
      "flutterInAppWebViewPlatformReady",
      function (event) {
        window.flutter_inappwebview
          .callHandler("open_camera_take_picture")
          .then((outputs) => {
            console.log(outputs);
            if (outputs.length == 2) {
              const photo_path = outputs[0];
              const photo_buffer = outputs[1];
            } else {
              console.log("failed taking photo");
            }
          });
      }
    );
  }, []);

  const [openC, setOpenC] = useState(false);
  const openCAM = () => {
    setOpenC(true);
  };

  return (
    <>
      {openC ? (
        <div>
          <CAM setOpenC={setOpenC} openC={openC} />
        </div>
      ) : (
        <>
          <div className={`relative ${isUpload ? "is-upload " : ""} `}>
            <img
              src={BACKGROUND_CONTENT}
              className="relative max-h-full h-full"
            />
            <div className="relative bg-bottom-guide flex flex-col items-center mt-6 rounded-t-[32px] backdrop-blur-sm">
              {imageFile !== undefined ? (
                ""
              ) : (
                <>
                  <div className="header-info text-[#E7212F] uppercase mt-0 w-80 text-center leading-7 mb-1">
                    hướng dẫn chụp hóa đơn
                  </div>
                  <div className="w-full px-5 flex flex-col justify-center items-center">
                    <div className="w-full">
                      <Carousel
                        className="style-carousel"
                        autoPlay
                        centerMode={false}
                        showArrows={false}
                        infiniteLoop={true}
                        showThumbs={false}
                        showStatus={false}
                        preventMovementUntilSwipeScrollTolerance={false}
                        renderIndicator={(
                          onClickHandler,
                          isSelected,
                          index,
                          label
                        ) => {
                          const defStyle = (
                            <img src={ICON_DOTS} className="w-3 h-3" />
                          );
                          const defStylePrimary = (
                            <img src={ICON_DOTS_PRIMARY} className="w-3 h-3" />
                          );
                          const style = isSelected
                            ? { ...defStylePrimary }
                            : { ...defStyle };
                          return (
                            <span
                              className="inline-block px-2"
                              onClick={onClickHandler}
                              onKeyDown={onClickHandler}
                              value={index}
                              key={index}
                              role="button"
                              tabIndex={0}
                              aria-label={`${label} ${index + 1}`}
                            >
                              {style}
                            </span>
                          );
                        }}
                      >
                        {images.map((item) => (
                          <div key={item.id}>
                            <CarouselTakeAPhoto item={item} />
                          </div>
                        ))}
                      </Carousel>
                    </div>
                    <div className="flex mt-[100px] bg-[#FFF7D8] w-full mx-6 rounded-lg">
                      <div className="w-16 mt-3 flex items-start justify-center">
                        <img src={ICON_EXPLAIN} />
                      </div>
                      <div className="w-full mt-3">
                        <div className="title-description-bill ">
                          Hóa đơn quá dài{" "}
                        </div>
                        <div className="content-description-bill mt-1">
                          Gắp hóa đơn lại để phần tên & giá sản phẩm & số tham
                          chiếu cùng một khung hình
                        </div>
                        <div className="title-description-bill mt-3">
                          Chụp nhiều lần nhưng hệ thống không nhận dạng:
                        </div>
                        <div className="content-description-bill mt-1 mb-2">
                          Vui lòng gọi Hotline hoặc email về contact@mvc.com.vn
                          để được hỗ trợ
                        </div>
                      </div>
                    </div>

                    <div className="flex mt-[15px] justify-between mb-[80px]">
                      <div className="color-button-disable btn-takephoto mr-2 ">
                        <div className="flex flex-col justify-center w-1">
                          <i className="fa-solid fa-chevron-left fa-solid-back corlor-text-darkblack pl-5" />
                        </div>
                        <NavLink
                          to={`/wilmar` + data_wilmar}
                          className="text-btn-cancel btn-text justify-center flex items-center"
                        >
                          Trở lại
                        </NavLink>
                      </div>
                      <div className="color-button-yellow btn-takephoto ml-02 ">
                        <div className="flex flex-col justify-center">
                          <img src={BTN_NEXT_GUIDE} className=" h-5 w-5" />
                        </div>
                        <button
                          onClick={() => openCAM()}
                          className="text-btn-cancel -ml-7 btn-text corlor-text-darkblack w-40 font-semibold-mon flex justify-center items-center"
                        >
                          Tiếp tục
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <FooterHasNavbar />
          </div>
          <div className="background-megaf relative -top-5"></div>
        </>
      )}
    </>
  );
}
