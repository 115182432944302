import "../../assets/css/participateHistory.css";
import IconPhoneAndZalo from "../../component/IconPhoneAndZalo/IconPhoneAndZalo";
import ParticipateHisItem from "./ParticipateHisItem";
import BACKGROUND_CONTENT from "../../assets/fontawesome/image/background-content.png";
import { useEffect, useState } from "react";
import { historyService } from "../../services/apiService/historyServices";
import { userDataLocal } from "../../services/localService/localService";
import { setAuthorization } from "../../services/apiService/configURL";
import FooterHasNavbar from "../../component/Footer/FooterHasNavbar";
import NavbarHome from "../../component/NavbarHome/NavbarHome";
import WARNING from "../../assets/fontawesome/image/warning.png";
import Loading from "../../component/Loading/Loading";

const TITLE = "Lịch sử tham gia";
export default function ParticipateHistory() {
  const [listHistory, setListHistory] = useState([]);
  const [status, setStatus] = useState(false);
  let { token } = userDataLocal.get();
  setAuthorization(token);
  useEffect(() => {
    setStatus(true);
    historyService
      .getListHistory()
      .then((res) => {
        setStatus(false);
        console.log(res);
        const resp = res.list_reward.filter((x) => x.campaign_id === 109);
        setListHistory(resp);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  return (
    <div>
      {status ? <Loading /> : null}
      <div>
        <img src={BACKGROUND_CONTENT} className="fixed" />
      </div>
      <div className=" absolute bottom-0 h-[98%] w-full z-10">
        {listHistory?.length === 0 ? (
          <div className="relative">
            <div className="absolute top-40 left-1/2 -translate-x-1/2">
              <img src={WARNING} />
              <div className="text-center font-semibold-mon text-black mt-5">
                Hiện tại, bạn vẫn chưa có lịch sử tham gia. Tham gia ngay để
                nhận ngay cơ hội săn quà siêu hấp dẫn.
              </div>
            </div>
          </div>
        ) : (
          <ul className="containerNotify__background-list pt-8 box-border z-20 ">
            {listHistory?.map((lstProduct, index) =>
              index % 2 === 0 ? (
                <li className="participatehis-item bg-c" key={index}>
                  <ParticipateHisItem lstProduct={lstProduct} />
                </li>
              ) : (
                <li className="participatehis-item bg-l" key={index}>
                  <ParticipateHisItem lstProduct={lstProduct} />
                </li>
              )
            )}
          </ul>
        )}
        <div className="h-10"></div>
      </div>
      <NavbarHome />
      <FooterHasNavbar />
      <IconPhoneAndZalo />
    </div>
  );
}
