import { useEffect, useState } from "react";

import HeaderBackground from "./HeaderBackground";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import { userServices } from "../../services/apiService/userServices";
import Logo from "../../assets/fontawesome/image/logo_mvc.png";
import Gach from "../../assets/fontawesome/image/gach.png";

import edit_icon from "../../assets/fontawesome/image/edit-icon.png";
import IconPhoneAndZalo from "../../component/IconPhoneAndZalo/IconPhoneAndZalo";
import { userDataLocal } from "../../services/localService/localService";
import { ErrorMessage } from "@hookform/error-message";
import moment from "moment";
import { toast } from "react-toastify";
import RegisterComponent from "../../component/RegisterComponent/RegisterComponent";
const TITLE = "Cập nhật thông tin";
const style = {
  backgroundColor: "#EEF5FC",
  color: "#130682",
  fontSize: "16px",
  fontHeight: "20px",
  fontWeight: "600",
};
const updateInfo = "UPDATE_INFOR";
export default function UpdateCustomerInfo() {
  return (
    <div>
      <HeaderBackground TITLE={TITLE} buttonBack={"/wilmar"} edit={edit_icon} />
      <div className=" w-full bg-white rounded-[30px_30px_0_0] absolute top-[96px] z-50">
        <div className="flex justify-center items-center px-[25px] mt-[70px]  max-h-full ">
          <div className="block -mt-10">
            <RegisterComponent updateInfo={updateInfo} />
          </div>
        </div>
        <div className="form__footer corlor-text-darkblue">
          <img src={Logo} alt="" />
          <img src={Gach} className="form__space" alt="" />
          <div className="form__footer-copyright">
            Copyright © 2023 MVC Company. All rights reserved.
          </div>
        </div>
      </div>
      <IconPhoneAndZalo />
    </div>
  );
}
