import ConfirmPopupLogin from "../../component/ConfirmPopupLogin/ConfirmPopupLogin";
import ICON_HOME_COLOR from "../../assets/fontawesome/image/home_icon_color.svg";
import ICON_PROFILE_COLOR from "../../assets/fontawesome/image/profile_icon-color.svg";
import ICON_GIFT_COLOR from "../../assets/fontawesome/image/gift_icon-color.svg";
import ICON_TAKEPHOTO_COLOR from "../../assets/fontawesome/image/takephoto_icon-color.svg";
import "../../assets/css/Home.css";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { setAuthorization } from "../../services/apiService/configURL";
import { userDataLocal } from "../../services/localService/localService";
let titlePopup = "Vui lòng đăng nhập để thấy được chức năng này";
export default function NavbarHome({}) {
  let link_home = JSON.parse(localStorage.getItem("WILMAR_HOME"));
  const location = useLocation();
  let { token } = userDataLocal.get();
  console.log(token);
  setAuthorization(token);
  const [popup, setPopup] = useState(false);
  const navigation = useNavigate();
  const handleClickMenuHome = () => {
    setAuthorization(token);
    navigation("/wilmar" + link_home);
  };

  const handleClickMenuTakePhoto = () => {
    navigation("/wilmar/list-rotation");
    setAuthorization(token);
  };
  const handleClickMenuProfile = () => {
    setAuthorization(token);
    navigation("/wilmar/infor-customer");
  };
  const handleClickMenuGift = () => {
    setAuthorization(token);
    navigation("/wilmar/list-gift");
  };
  return (
    <div className="navbar__home w-[100%] h-[88px] rounded-t-2xl bg-[#fff] fixed bottom-0 left-0 z-20">
      <div className="flex justify-between">
        <ul className="px-[1.5625rem] mt-[6px] sm:mt-7 h-12 w-full flex justify-between">
          {location?.pathname === "/wilmar" ? (
            <li className="icon-nav-home max-[423px]:w-[8rem] w-36">
              <img src={ICON_HOME_COLOR} className="w-36 h-12" />
            </li>
          ) : (
            <li
              className="navbar__home-Iconhome icon-navbar "
              onClick={handleClickMenuHome}
            ></li>
          )}

          {location?.pathname === "/wilmar/list-rotation" ? (
            <li className="icon-nav-home max-[423px]:w-[8rem] w-36">
              <img src={ICON_TAKEPHOTO_COLOR} className="w-36 h-12" />
            </li>
          ) : (
            <li
              className=" navbar__home-Icontake icon-navbar"
              onClick={handleClickMenuTakePhoto}
            ></li>
          )}

          {location?.pathname === "/wilmar/list-gift" ? (
            <li className="icon-nav-home max-[423px]:w-[8rem] w-36">
              <img src={ICON_GIFT_COLOR} className="w-36 h-12" />
            </li>
          ) : (
            <li
              className="navbar__home-Icongift  icon-navbar"
              onClick={handleClickMenuGift}
            ></li>
          )}

          {location?.pathname === "/wilmar/infor-customer" ? (
            <li className="icon-nav-home max-[423px]:w-[8rem] w-36">
              <img src={ICON_PROFILE_COLOR} className="w-36 h-12" />
            </li>
          ) : (
            <li
              className="navbar__home-Iconprofile icon-navbar "
              onClick={handleClickMenuProfile}
            ></li>
          )}
          {popup ? (
            <ConfirmPopupLogin
              titlePopup={titlePopup}
              onClosePopup={() => {
                setPopup(false);
              }}
              handleCancel={() => {
                navigation("/wilmar" + link_home);
              }}
              handleOk={() => {
                navigation("/wilmar/login");
              }}
            />
          ) : null}
        </ul>
      </div>
    </div>
  );
}
