import Compressor from "compressorjs";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import uuid from "react-uuid";
import { apiOdoo } from "../../services/apiService/homeServices";
import Test from "../../assets/fontawesome/image/test.jpg";

import {
  CAMPAIGN_INFO,
  URL_API_BE_ODOO1,
  URL_SEND_WITH_API,
} from "../../services/apiService/LuckyDraw";
import { format } from "date-fns";
import { useNavigate, useParams } from "react-router";
import ConfirmPopupLogin from "../../component/ConfirmPopupLogin/ConfirmPopupLogin";
import ModalGift from "../GuideTakeAPhoto/ModalGift";
import ConfirmPopupBtnHorizontal from "../../component/ConfirmPopupBtnHorizontal/ConfirmPopupBtnHorizontal";
import { ocrServices } from "../../services/apiService/ocrServices";

export default function CAM({ setOpenC, openC }) {
  const data_wilmar = JSON.parse(localStorage.getItem("WILMAR_HOME"));
  const navigate = useNavigate();
  const [isShowModalErr, setIsShowModalErr] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [isUpload, setIsUpload] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [imageFile, setimageFile] = useState(undefined);
  const [photo_path, setPhoto_Path] = useState(undefined);
  const [isAskLogin, setIsAskLogin] = useState(false);
  const [image, setImg] = useState(undefined);
  let infor_data = JSON.parse(localStorage.getItem("infor_user") || "{}");
  let appCode = window.location.pathname.split("/")[1];
  let cpData = JSON.parse(localStorage.getItem(CAMPAIGN_INFO) || "{}");
  let { campaignId } = useParams();
  const [so_ids, setSo_ids] = useState([]);
  const [listPrize, setListPrize] = useState([]);

  const handlePopupErrorOk = () => {
    console.log("popup press ok");
    navigate("/wilmar" + data_wilmar);
  };
  const handlePopupErrorOkImg = () => {
    console.log("popup press ok");
    navigate("/wilmar/guide-takeaphoto");
  };
  let webviewCallbackReady = false;
  useEffect(() => {
    window.addEventListener(
      "flutterInAppWebViewPlatformReady",
      function (event) {
        webviewCallbackReady = true;
      }
    );
  });

  useEffect(() => {
    window.flutter_inappwebview
      .callHandler("open_camera_take_picture", null)
      .then((outputs) => {
        setPhoto_Path(outputs[0]);
        const a = arrayBufferToBase64(outputs[1]);
        if (a === null || a === "" || a === undefined) {
          // navigate("/wilmar" + data_wilmar);
          setOpenC(false);
        } else {
          if (outputs.length == 2) {
            setImg("data:image/jpeg;base64," + a);
          } else {
            setImg(undefined);
            setimageFile(undefined);
          }
        }
      });
  }, []);
  const handleChangeImage = (event) => {
    console.log(event);

    let fileUploaded = event;
    console.log(fileUploaded);

    const fileUploadedSize = fileUploaded.size / 1024 / 1024;
    if (fileUploadedSize > 20) {
      new Compressor(fileUploaded, {
        quality: 0.4, // 0.6 can also be used, but its not recommended to go below.
        success: (res) => {
          setimageFile(res);
        },
      });
    } else if (fileUploadedSize > 10 && fileUploadedSize <= 20) {
      new Compressor(fileUploaded, {
        quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
        success: (res) => {
          setimageFile(res);
        },
      });
    } else if (fileUploadedSize > 6 && fileUploadedSize <= 10) {
      new Compressor(fileUploaded, {
        quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
        success: (res) => {
          setimageFile(res);
        },
      });
    } else if (fileUploadedSize > 3 && fileUploadedSize <= 6) {
      new Compressor(fileUploaded, {
        quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
        success: (res) => {
          setimageFile(res);
        },
      });
    } else {
      new Compressor(fileUploaded, {
        quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
        success: (res) => {
          setimageFile(res);
        },
      });
    }
  };
  const submitReceipt = (gcsResult) => {
    console.log(gcsResult);
    let formData = new FormData();
    formData.append("gsutil_url", gcsResult.gsutil_url);
    formData.append("public_url", gcsResult.public_url);
    formData.append("ocr_result", gcsResult.data);
    formData.append("phone", infor_data?.cus_phone);
    formData.append("customer_name", infor_data?.cus_name);
    formData.append(
      "request_id",
      uuid() + "-" + format(new Date(), "ddMMyyyyHHmmss")
    );
    formData.append(
      "receipt_datetime",
      format(new Date(), "yyyy-MM-dd HH:mm:ss")
    );

    formData.append("url", "https://mm.mvc.com.vn/wilmar");
    if (gcsResult.campaign_id) {
      formData.append("campaign_id", gcsResult.campaign_id);
    }
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    apiOdoo
      .postData(`${URL_API_BE_ODOO1}ocr/submit_receipt`, formData, headers)
      .then((res_receipt) => {
        console.log(res_receipt);
        // alert(JSON.stringify(res.data));
        localStorage.removeItem("GCS_RESULT");
        // setisSubmitRun(false);
        console.log(res_receipt);
        if (
          res_receipt.status === 200 &&
          res_receipt.data.result.meta.status === true
        ) {
          setIsUpload(false);
          let url = "";
          let pg_so_code = res_receipt.data.result.data.pg_so_code;
          if (cpData.prize_type === "use_lucky_draw") {
            url = "/wilmar/wheel/" + pg_so_code;
            navigate(url);
          }
        } else {
          setErrMsg(res_receipt.data.result.meta.message);
          setIsShowModalErr(true);
          setIsUpload(false);
        }
      })
      .catch((e) => {
        // alert(e);
        // console.log(e);
        setIsUpload(false);
      });
  };
  useEffect(() => {
    console.log(image);
    if (image !== undefined) {
      urltoFile(image, uuid() + uuid() + ".jpg", "image/jpeg").then(function (
        file
      ) {
        handleChangeImage(file);
      });
    }
  }, [image]);

  function urltoFile(url, filename, mimeType) {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      });
  }
  function open_camera_take_picture() {
    setimageFile(undefined);
    setImg(undefined);
    window.flutter_inappwebview
      .callHandler("open_camera_take_picture", null)
      .then((outputs) => {
        // alert(outputs.length);
        setPhoto_Path(outputs[0]);
        const a = arrayBufferToBase64(outputs[1]);
        if (a === null || a === "" || a === undefined) {
          setOpenC(false);
        } else {
          if (outputs.length == 2) {
            setImg("data:image/jpeg;base64," + a);
          } else {
            setImg(undefined);
            setimageFile(undefined);
          }
        }
      })
      .catch((e) => {
        setimageFile(undefined);
        setImg(undefined);
      });
  }
  function arrayBufferToBase64(buffer) {
    var binary = "";
    var bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b) => (binary += String.fromCharCode(b)));
    return window.btoa(binary);
  }
  const onClickDeleteImg = (e) => {
    navigate("/wilmar/guide-takeaphoto");
  };

  const [isShowModalImg, setIsShowModalImg] = useState(false);
  const pushImageToGCS = () => {
    if (imageFile === undefined) {
      setIsShowModalImg(true);
    } else {
      setIsUpload(true);
      console.log("hello");
      let formDataGCS = new FormData();
      console.log("minh");
      formDataGCS.append("file", imageFile);
      const fileName =
        uuid() +
        "_" +
        format(new Date(), "dd-MM-yyyy-HH-mm-ss") +
        "_" +
        imageFile.name;
      formDataGCS.append("fileName", fileName);
      formDataGCS.append("ocrBase", cpData.ocr_endpoint);
      // alert("vô push gcs");
      ocrServices
        .uploadImageToOCR(formDataGCS)
        .then((res) => {
          console.log(res);
          if (campaignId) {
            console.log("campaignId", campaignId);
            res.data.campaign_id = campaignId;
          }
          localStorage.setItem("GCS_RESULT", JSON.stringify(res.data));
          // alert(JSON.stringify(res.data));
          submitReceipt(res.data);
        })
        .catch((err) => {
          console.log(err);
          // toast.error(err);
          setIsUpload(false);
        });
    }
  };
  return (
    <>
      {isUpload ? (
        <div className="z-[200] w-full  flex justify-center items-center absolute full-height backdrop-blur-sm flex-col">
          <div className="">
            <button className="w-[100px] h-[100px] load"></button>
          </div>
          <div className="flex justify-center text-center px-[30px] ">
            <p className="bg-slate-100 rounded-xl font-light-mon p-[10px] ">
              Hóa đơn của Quý khách đang được hệ thống ghi nhận. Vui lòng không
              rời khỏi màn hình hoặc tắt trình duyệt
            </p>
          </div>
        </div>
      ) : (
        ""
      )}

      {/* <div className="container"> */}
      {imageFile === undefined ? (
        <>
          <div className="z-[200] w-full  flex justify-center items-center absolute full-height backdrop-blur-sm flex-col">
            <div className="">
              <button className="w-[100px] h-[100px] load"></button>
            </div>
            <div className="flex justify-center text-center px-[30px] ">
              <p className="bg-slate-100 rounded-xl font-light-mon p-[10px] ">
                Ảnh của bạn đang được hệ thống xử lí. Vui lòng chờ trong giây
                lát
              </p>
            </div>
          </div>
        </>
      ) : (
        <div className="relative top-0">
          <img
            src={URL.createObjectURL(imageFile)}
            // src={imageFile}
            className="min-h-screen"
            alt="upload view"
          />

          <div className="flex justify-center gap-[12px] p-4 absolute bottom-16 left-1/2 -translate-x-1/2">
            <button
              onClick={(e) => open_camera_take_picture(e)}
              className="bg-[#FFF000] w-36 border-0 text-[#130682] px-[5px] py-[13px] text-center inline-block rounded-[13px]"
            >
              Chụp lại
            </button>

            <button
              onClick={() => {
                pushImageToGCS();
              }}
              className="bg-[#0367C1] w-36 border-0 text-[#FFFFFF] px-[5px] py-[13px] text-center inline-block rounded-[13px]"
            >
              Xác nhận
            </button>
          </div>

          <ModalGift
            modalIsOpen={modalIsOpen}
            soIds={so_ids}
            listPrize={listPrize}
          ></ModalGift>
          <ConfirmPopupBtnHorizontal
            isShow={isShowModalErr}
            labelOk="Đồng ý"
            message={errMsg}
            handleOk={handlePopupErrorOk}
          />
          <ConfirmPopupBtnHorizontal
            isShow={isShowModalImg}
            labelOk="Đồng ý"
            message={"Vui lòng chọn hình để up lên hệ thống"}
            handleOk={handlePopupErrorOkImg}
          />
        </div>
      )}
      {/* </div> */}
    </>
  );
}
