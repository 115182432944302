import { axiosInstance, configHeader } from "./configURL";

export const historyService = {
  getListHistory: () => {
    return axiosInstance.post(
      "/mega_market/get_participation_records",
      configHeader
    );
  },
};
