import "./App.css";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Login from "./pages/LoginPage/Login";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import Home from "./pages/Home/Home";
import Register from "./pages/RegisterPage/Register";
import TakeAPhoto from "./pages/TakeAPhoto/TakeAPhoto";
import HotdealDetails from "./pages/HotdealDetails/HotdealDetails";
import ListRotation from "./pages/ListRotation/ListRotation";
import GuidePage from "./pages/GuidePage/GuidePage";
import ListGift from "./pages/ListGift/ListGift";
import PrizeRules from "./pages/PrizeRules/PrizeRules";
import GuideTakeAPhoto from "./pages/GuideTakeAPhoto/GuideTakeAPhoto";
import ParticipateHistory from "./pages/ParticipateHistory/ParticipateHistory";
import ListPromotion from "./pages/ListPromotion/ListPromotion";
import InforCustomer from "./pages/InforCustomer/InforCustomer";
import Wheel from "./pages/SpinTheWheel/Wheel";
import WheelResult from "./pages/SpinTheWheel/WheelResult";

import Success from "./pages/Success/Success";
import AnnouncePrize from "./pages/AnnouncePrize/AnnouncePrize";
import ConfirmOTP from "./pages/ConfirmOTP/ConfirmOTP";
import UpdateCustomerInfo from "./pages/UpdateCustomerInfo/UpdateCustomerInfo";
import ProtectedRoute from "./HOC/ProtectedLayout/ProtectedRoute";
import LoginNoRegister from "./pages/LoginPageNoRegister/LoginNoRegister";
import { userDataLocal } from "./services/localService/localService";
import { setAuthorization } from "./services/apiService/configURL";
import {
  isLocalhost,
  isMobileDevice,
  isWebKitBrowser,
} from "./services/deviceModel";
import NoAccessBrowser from "./pages/NoAccessBrowser/NoAccessBrowser";
import ListNotify from "./pages/ListNotify/ListNotify";
import DetectWilmar from "./pages/DetectWilMar/DetectWilmar";
import CAM from "./pages/CAM/CAM";
import DetectToken from "./pages/DetectWilMar/DetectToken";

function App() {
  let { token } = userDataLocal.get();
  console.log(token);
  // setAuthorization(token);
  let access = true;
  let mobileDevice = isMobileDevice();
  let webKitBrowser = isWebKitBrowser();
  if ((mobileDevice && !webKitBrowser) || isLocalhost()) {
    access = true;
  }
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {access ? (
        <div className="App">
          <BrowserRouter>
            <Routes>
              <Route path="/wilmar" element={<Home />} />
              <Route path="/wilmar/cam" element={<CAM />} />
              <Route path="/wilmar/detect" element={<DetectWilmar />} />
              <Route path="/wilmar/detectapp" element={<DetectToken />} />
              <Route
                path="/wilmar/login-no-register"
                element={<LoginNoRegister />}
              />

              <Route path="/wilmar/takephoto" element={<TakeAPhoto />} />
              <Route path="/wilmar/confirm-otp" element={<ConfirmOTP />} />

              <Route path="/wilmar/register" element={<Register />} />

              <Route path="/wilmar/list-notify" element={<ListNotify />} />

              <Route
                path="/wilmar/deal-details/:id"
                element={<HotdealDetails />}
              />

              <Route path="/wilmar/guide" element={<GuidePage />} />
              <Route path="/wilmar/prize-rules" element={<PrizeRules />} />

              <Route
                path="/wilmar/participate-history"
                element={<ParticipateHistory />}
              />
              <Route
                path="/wilmar/list-promotion"
                element={<ListPromotion />}
              />

              <Route path="/wilmar/wheel-success" element={<Success />} />
              <Route
                path="/wilmar/announce-prize"
                element={<AnnouncePrize />}
              />

              <Route
                path="/wilmar/guide-takeaphoto/:campaignId?"
                element={<GuideTakeAPhoto />}
              />
              <Route
                path="/wilmar/update-customer-info"
                element={<UpdateCustomerInfo />}
              />
              <Route element={<ProtectedRoute />}>
                <Route
                  path="/wilmar/infor-customer"
                  element={<InforCustomer />}
                />

                <Route path="/:appcode/wheel/:code" element={<Wheel />} />
                <Route
                  path="/:appcode/wheelresult/:code"
                  element={<WheelResult />}
                />
                <Route path="/wilmar/list-gift" element={<ListGift />} />

                <Route
                  path="/wilmar/list-rotation"
                  element={<ListRotation />}
                />
              </Route>
            </Routes>
          </BrowserRouter>
        </div>
      ) : (
        <BrowserRouter>
          <Routes>
            <Route path="*" element={<NoAccessBrowser />} />
          </Routes>
        </BrowserRouter>
      )}
    </>
  );
}

export default App;
