import {
  URL_API_BE_ODOO,
  axiosInstance,
  campaignURL,
  config,
} from "./configURL";

const data = JSON.stringify({});
const headers = {
  accept: "application/json, text/plain, */*",
  "content-type": "application/json;charset=UTF-8",
};
export const userServices = {
  getMyPrizeList: (statusGifts) => {
    return URL_API_BE_ODOO.post(`/mega_market/get_my_reward`, {});
  },
  // countDraw: (statusGifts) => {
  //   return URL_API_BE_ODOO.post(`/mega_market/get_my_reward`, {});
  // },ocr/count_draw
  // getCampaignInfo: () => {
  //   return axiosInstance.get("/ocr/campaign_info", {
  //     params: {
  //       url: campaignURL,
  //     },
  //   });
  // },
  // postUserLogin: (data) => {
  //   return axiosInstance.post("/bud/login", { params: data });
  // },

  // postUserLoginNoRegister: (data) => {
  //   return axiosInstance.post("/bud/sign_in", { params: data });
  // },

  // postValidateOTP: (data) => {
  //   return axiosInstance.post("/bud/validate_otp", { params: data });
  // },
  // postResendOTP: () => {
  //   return axiosInstance.post("/mvc/resend_otp", {
  //     params: {
  //       url: campaignURL,
  //     },
  //   });
  // },
  // getUserInfo: (id) => {
  //   return axiosInstance.get(`/bud/customer_info?pnj_customer_id=${id}`);
  // },

  // postUserInfo: (userInfo) => {
  //   return axiosInstance.post("/bud/update_customer_info", {
  //     params: userInfo,
  //   });
  // },
  postUpdateUrbox: (data) => {
    return axiosInstance.post("/ocr/redeem_gift", { params: data });
  },
  postUpdateImage: (data) => {
    return axiosInstance.post("/ocr/upload_reward_image", { params: data });
  },
};
