import React, { useEffect, useState } from "react";
import ModalGift from "../../pages/GuideTakeAPhoto/ModalGift";
import ConfirmPopupBtnHorizontal from "../../component/ConfirmPopupBtnHorizontal/ConfirmPopupBtnHorizontal";
import { useNavigate } from "react-router-dom";
import uuid from "react-uuid";
import { format } from "date-fns";
import { receiptServices } from "../../services/apiService/receiptServices";
import * as LINK_HOME from "../../services/localService/linkHome";

export default function SubmitReceipt({ trigger }) {
  let link_home = LINK_HOME.LINK_HOME;
  let gcsResult = JSON.parse(localStorage.getItem("GCS_RESULT"));
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [listPrize, setListPrize] = useState([]);
  const [so_ids, setSo_ids] = useState([]);
  const [isShowModalErr, setIsShowModalErr] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [isUpload, setIsUpload] = useState(false);
  const navigation = useNavigate();
  useEffect(() => {
    console.log(trigger);
    if (trigger) {
      submitReceipt(gcsResult);
    }
  }, [trigger]);

  const submitReceipt = (gcsResult) => {
    console.log(gcsResult);
    setIsUpload(true);
    let formData = new FormData();
    formData.append("gsutil_url", gcsResult.gsutil_url);
    formData.append("public_url", gcsResult.public_url);
    formData.append("ocr_result", gcsResult.data);
    formData.append("phone", localStorage.getItem("phoneData"));
    formData.append("customer_name", localStorage.getItem("nameData"));
    if (gcsResult.campaign_id) {
      formData.append("campaign_id", gcsResult.campaign_id);
    }
    formData.append(
      "request_id",
      uuid() + "-" + format(new Date(), "ddMMyyyyHHmmss")
    );
    formData.append(
      "receipt_datetime",
      format(new Date(), "yyyy-MM-dd HH:mm:ss")
    );
    receiptServices
      .submitReceiptApi(formData)
      .then((res) => {
        setListPrize(res.prize_list);
        setSo_ids(res.so_ids);
        setModalIsOpen(true);
      })
      .catch((err) => {
        setErrMsg(err);
        setIsShowModalErr(true);
      })
      .finally(() => {
        setIsUpload(false);
        localStorage.removeItem("GCS_RESULT");
      });
  };
  const handlePopupErrorOk = () => {
    console.log("popup press ok");
    navigation("/wilmar" + link_home);
  };
  return (
    <>
      {isUpload ? (
        <div className="z-[200] w-full  flex justify-center items-center absolute full-height backdrop-blur-sm flex-col">
          <div className="">
            <button className="w-[100px] h-[100px] load"></button>
          </div>
          <div className="flex justify-center text-center px-[30px] ">
            <p className="bg-slate-100 rounded-xl font-light-mon p-[10px] text-black">
              Hóa đơn của Quý khách đang được hệ thống ghi nhận. Vui lòng không
              rời khỏi màn hình hoặc tắt trình duyệt
            </p>
          </div>
        </div>
      ) : (
        ""
      )}
      <ModalGift
        modalIsOpen={modalIsOpen}
        soIds={so_ids}
        listPrize={listPrize}
      ></ModalGift>
      <ConfirmPopupBtnHorizontal
        isShow={isShowModalErr}
        labelOk="Đồng ý"
        message={errMsg}
        handleOk={handlePopupErrorOk}
      />
    </>
  );
}
