import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../../assets/css/wheel.css";
import spin_img from "../../assets/fontawesome/image/rotaryNeedle.png";
import wheel_text from "../../assets/fontawesome/image/wheel-text.png";
import BACKGROUN_WHEEL from "../../assets/fontawesome/image/wheel-bg.png";
import KIMQUAY from "../../assets/fontawesome/image/kimquay.png";
import {
  CAMPAIGN_PGCODE,
  URL_SEND_WITH_API,
  WHEEL_HAS_LUOTQUAY,
  WHEEL_LUOTQUAY,
  WHEEL_PHANTHUONG,
  luckyDrawService,
} from "../../services/apiService/LuckyDraw";
import { crudApi } from "../../services/apiService/crudApi";
import { userDataLocal } from "../../services/localService/localService";
import { setAuthorization } from "../../services/apiService/configURL";
import FooterHasNavbar from "../../component/Footer/FooterHasNavbar";
import { toast } from "react-toastify";
import ConfirmPopupBtnHorizontal from "../../component/ConfirmPopupBtnHorizontal/ConfirmPopupBtnHorizontal";

const Home = () => {
  const navigate = useNavigate();
  const appCode = window.location.pathname.split("/")[1];
  const [hasWheel, sethasWheel] = useState("TRUE");
  let pgCode = window.location.pathname.split("/")[3];
  localStorage.setItem(CAMPAIGN_PGCODE, pgCode);
  const [loading, setloading] = useState(true);
  let { token } = userDataLocal.get();

  const [openPopup, setOpenPopup] = useState(false);
  const [dataCallUrbox, setDataCallUrbox] = useState();
  setAuthorization(token);
  const [soqua, setsoqua] = useState(8);
  const [win, setwin] = useState(0);
  const [transformBox, settransformBox] = useState("");
  const [segments, setsegments] = useState([]);
  const [isSpinning, setIsSpinning] = useState(false);
  console.log(isSpinning);
  const redirectToWheelResult = () => {
    navigate("/" + appCode + "/wheelresult/" + pgCode);
  };
  const countDraw = async () => {
    let params = {
      pg_so_code: pgCode,
      url: URL_SEND_WITH_API + appCode,
      count: 1,
    };
    console.log(params);
    // await crudApi
    //   .countDraw(param, "ocr/count_draw")
    luckyDrawService
      .countDraw(params)
      .then((res) => {
        console.log(res);
        let response = res;
        const { voucher_line_id } = JSON.parse(
          localStorage.getItem(WHEEL_PHANTHUONG)
        );
        setDataCallUrbox(
          response.gift_no + response.gift_extra_no - response.count_draw
        );
        localStorage.setItem("VOUCHER_LINE", voucher_line_id);
        console.log(
          "luot quay count: ",
          response.gift_no + response.gift_extra_no - response.count_draw
        );
        if (response.gift_no >= response.count_draw) {
          localStorage.setItem(
            WHEEL_LUOTQUAY,
            "" +
              (response.gift_no + response.gift_extra_no - response.count_draw)
          );
          if (response.gift_no - response.count_draw >= 1) {
            localStorage.setItem(WHEEL_HAS_LUOTQUAY, "TRUE");
          } else {
            localStorage.setItem(WHEEL_HAS_LUOTQUAY, "FAlSE");
          }
        } else {
          localStorage.setItem(
            WHEEL_LUOTQUAY,
            "" +
              (response.gift_no + response.gift_extra_no - response.count_draw)
          );
          if (
            response.gift_no + response.gift_extra_no - response.count_draw >=
            1
          ) {
            localStorage.setItem(WHEEL_HAS_LUOTQUAY, "TRUE");
          } else {
            localStorage.setItem(WHEEL_HAS_LUOTQUAY, "FAlSE");
          }
        }
      })
      .catch(() => {
        setIsSpinning(false);
      });
  };
  const runWheel = () => {
    if (!isSpinning) {
      setIsSpinning(true);
      var array_toado = [
        { id: 1, toado: 0 },
        { id: 2, toado: -45 },
        { id: 3, toado: -90 },
        { id: 4, toado: -135 },
        { id: 5, toado: -180 },
        { id: 6, toado: -225 },
        { id: 7, toado: -270 },
        { id: 8, toado: -315 },
      ];
      if (win != 0) {
        console.log(win);
        var item = array_toado?.find((i) => i.id === win);
        let toado = item?.toado;
        console.log(toado);
        settransformBox("rotate(" + (toado + 360 * 6) + "deg)");
        var element = document.getElementById("mainbox");
        countDraw();
        setTimeout(function () {
          element.classList.remove("animate");
          redirectToWheelResult();
        }, 6000);
      }
    }
  };
  const interleave = ([x, ...xs], ys) => (x ? [x, ...interleave(ys, xs)] : ys);
  const getDataGift = () => {
    setloading(true);
    let params = {
      pg_so_code: pgCode,
      url: URL_SEND_WITH_API + appCode,
    };
    console.log(params);
    let dataResponse = {};
    luckyDrawService
      .postDrawLuckyGift(params)
      .then((dataResponse) => {
        console.log(dataResponse);
        if (
          dataResponse.count_draw ===
          dataResponse.gift_no + dataResponse.gift_extra_no
        ) {
          setOpenPopup(true);
        }
        setDataCallUrbox(
          dataResponse.gift_no +
            dataResponse.gift_extra_no -
            dataResponse.count_draw
        );
        console.log(dataResponse.count_draw);
        if (dataResponse.count_draw < dataResponse.gift_no) {
          console.log(dataResponse.count_draw);
          if (dataResponse.list_prizes !== undefined) {
            let list_prizes = dataResponse.list_prizes;
            let segmentsTemp = [];
            let segmentsBigHalfGiftTemp = [];
            let segmentsNormalGiftTemp = [];
            let gift_no = dataResponse.gift_no;
            let count_draw = dataResponse.count_draw;
            let winStateTemp = 0;
            if (gift_no > count_draw) {
              let gift_code_win = dataResponse.list_win[count_draw]?.gift_code;
              localStorage.setItem(
                WHEEL_PHANTHUONG,
                JSON.stringify(dataResponse.list_win[count_draw])
              );
              localStorage.setItem(
                "VOUCHER_LINE_WHEEL",
                dataResponse.list_win[count_draw].voucher_line_id
              );
              if (
                list_prizes !== undefined &&
                list_prizes !== null &&
                list_prizes.length > 0
              ) {
                for (let i = 0; i < list_prizes.length; i++) {
                  let a = list_prizes[i].gift_short_name;
                  var check = false;

                  let itemTemp = {
                    gift_code: list_prizes[i].gift_code,
                    image: list_prizes[i].gift_image,
                    text: a,
                    check: check,
                    gift_image: list_prizes[i].gift_image,
                  };
                  if (!list_prizes[i].gift_code.includes("biggift")) {
                    if (!list_prizes[i].gift_code.includes("bighalfgift")) {
                      segmentsNormalGiftTemp.push(itemTemp);
                    } else {
                      segmentsBigHalfGiftTemp.push(itemTemp);
                    }
                  }
                  segmentsTemp.push(itemTemp);
                }
                let a = 0;
                var segmentsTempList = segmentsTemp.concat(
                  segmentsBigHalfGiftTemp
                );
                if (segmentsTempList.length < soqua) {
                  a = 8 - segmentsTempList.length;
                  var array = [];
                  for (let i = 0; i < a; i++) {
                    array.push(
                      segmentsNormalGiftTemp[
                        Math.floor(
                          Math.random() * segmentsNormalGiftTemp.length
                        )
                      ]
                    );
                  }
                  segmentsTemp = interleave(segmentsTempList, array);
                }
                for (let a = 0; a < segmentsTemp.length; a++) {
                  if (segmentsTemp[a].gift_code === gift_code_win) {
                    check = true;
                    winStateTemp = a + 1;
                  }
                  segmentsTemp[a] = { ...segmentsTemp[a], id: a + 1 };
                }
                setwin(winStateTemp);
              }
            } else {
            }
            setsegments(segmentsTemp);
            console.log("luotquay: ", gift_no - count_draw);
            // localStorage.setItem(WHEEL_LUOTQUAY, "" + (gift_no - count_draw));
          }
        } else {
          if (dataResponse.list_extra_prizes !== undefined) {
            console.log(dataResponse.list_extra_prizes);
            let list_extra_prizes = dataResponse.list_extra_prizes;
            let segmentsTemp = [];
            let segmentsBigHalfGiftTemp = [];
            let segmentsNormalGiftTemp = [];
            let gift_no = dataResponse.gift_no;
            let gift_extra_no = dataResponse.gift_extra_no;
            let count_draw = dataResponse.count_draw;
            let winStateTemp = 0;
            if (gift_no + gift_extra_no > count_draw) {
              let gift_code_win =
                dataResponse.list_extra_win[count_draw - gift_no]?.gift_code;
              localStorage.setItem(
                WHEEL_PHANTHUONG,
                JSON.stringify(
                  dataResponse.list_extra_win[count_draw - gift_no]
                )
              );
              localStorage.setItem(
                "VOUCHER_LINE_WHEEL",
                dataResponse.list_extra_win[count_draw - gift_no]
                  .voucher_line_id
              );
              if (
                list_extra_prizes !== undefined &&
                list_extra_prizes !== null &&
                list_extra_prizes.length > 0
              ) {
                for (let i = 0; i < list_extra_prizes.length; i++) {
                  let a = list_extra_prizes[i].gift;
                  var check = false;

                  let itemTemp = {
                    gift_code: list_extra_prizes[i].gift_code,
                    image: list_extra_prizes[i].gift_image,
                    text: a,
                    check: check,
                    gift_image: list_extra_prizes[i].gift_image,
                  };
                  if (!list_extra_prizes[i].gift_code.includes("biggift")) {
                    if (
                      !list_extra_prizes[i].gift_code.includes("bighalfgift")
                    ) {
                      segmentsNormalGiftTemp.push(itemTemp);
                    } else {
                      segmentsBigHalfGiftTemp.push(itemTemp);
                    }
                  }
                  segmentsTemp.push(itemTemp);
                }
                let a = 0;
                var segmentsTempList = segmentsTemp.concat(
                  segmentsBigHalfGiftTemp
                );
                if (segmentsTempList.length < soqua) {
                  a = 8 - segmentsTempList.length;
                  var array = [];
                  for (let i = 0; i < a; i++) {
                    array.push(
                      segmentsNormalGiftTemp[
                        Math.floor(
                          Math.random() * segmentsNormalGiftTemp.length
                        )
                      ]
                    );
                  }
                  segmentsTemp = interleave(segmentsTempList, array);
                }
                for (let a = 0; a < segmentsTemp.length; a++) {
                  if (segmentsTemp[a].gift_code === gift_code_win) {
                    check = true;
                    winStateTemp = a + 1;
                  }
                  segmentsTemp[a] = { ...segmentsTemp[a], id: a + 1 };
                }
                setwin(winStateTemp);
              }
            } else {
            }
            setsegments(segmentsTemp);
            console.log(segments);
            localStorage.setItem(
              WHEEL_LUOTQUAY,
              "" + (gift_no + gift_extra_no - count_draw)
            );
          }
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err);
      });
  };

  useEffect(() => {
    getDataGift();
    setloading(false);
  }, []);
  const data_wilmar = JSON.parse(localStorage.getItem("WILMAR_HOME"));
  const handlePopupErrorOkImg = () => {
    navigate(`/wilmar/list-rotation`);
  };

  return (
    <>
      {hasWheel === "TRUE" ? (
        <div className="spin-the-wheelbg" disabled="disabled">
          {segments.length === 0 ? (
            <></>
          ) : (
            <div>
              <Container className="wheel-container-vongquay">
                <div className="flex justify-center relative z-50 pt-[60px] wheel-text">
                  <img className="-mb-5 mt-1" src={wheel_text} alt="" />
                </div>
                <>
                  <div
                    id="bg"
                    className={isSpinning ? `pointer-events-none bg` : `bg`}
                    onClick={() => {
                      runWheel();
                    }}
                  >
                    <div className="absolute top-[49%] z-50 left-1/2 -translate-x-1/2">
                      <img src={KIMQUAY} className="w-[75px]" />
                    </div>
                    <button
                      id="buttonRun"
                      className="spin spin-btn z-20"
                      onClick={() => {
                        runWheel();
                      }}
                      disabled={isSpinning ? "disabled" : ""}
                    >
                      <img className="-z-10" src={spin_img} alt="" />
                    </button>
                    <div id="mainbox" className="mainbox ">
                      <img
                        src={BACKGROUN_WHEEL}
                        className="absolute z-0 w-[110%] -top-[15%]"
                      />
                      <div
                        id="box"
                        className="box nenvongquay"
                        style={{ transform: `${transformBox}` }}
                      >
                        <div className="box1">
                          <span className="segment span1 color-1">
                            <i
                              id="id_7"
                              className="item7 segments font-bold-mon"
                            >
                              <h6>{segments[6].text}</h6>
                              <img
                                src={segments[6].gift_image}
                                alt={segments[6].text}
                              />
                            </i>
                          </span>
                          <span className="segment span2 color-1">
                            <i
                              id="id_3"
                              className="item3 segments font-bold-mon"
                            >
                              <h6>{segments[2].text}</h6>
                              <img
                                src={segments[2].gift_image}
                                alt={segments[2].text}
                              />
                            </i>
                          </span>
                          <span className="segment span3 color-1">
                            <i
                              id="id_5"
                              className="item5 segments font-bold-mon"
                            >
                              <h6>{segments[4].text}</h6>
                              <img
                                src={segments[4].gift_image}
                                alt={segments[4].text}
                              />
                            </i>
                          </span>
                          <span className="segment span4 color-1">
                            <i
                              id="id_1"
                              className="item1 segments font-bold-mon"
                            >
                              <h6>{segments[0].text}</h6>
                              <img
                                src={segments[0].gift_image}
                                alt={segments[0].text}
                              />
                            </i>
                          </span>
                        </div>
                        <div className="box2">
                          <span className="segment span1 color-2">
                            <i
                              id="id_4"
                              className="item4 segments font-bold-mon"
                            >
                              <h6>{segments[3].text}</h6>
                              <img
                                src={segments[3].gift_image}
                                alt={segments[3].text}
                              />
                            </i>
                          </span>
                          <span className="segment span2 color-2">
                            <i
                              id="id_8"
                              className="item8 segments font-bold-mon"
                            >
                              <h6>{segments[7].text}</h6>
                              <img
                                src={segments[7].gift_image}
                                alt={segments[7].text}
                                className=""
                              />
                            </i>
                          </span>
                          <span className="segment span3 color-2">
                            <i
                              id="id_2"
                              className="item2 segments font-bold-mon"
                            >
                              <h6>{segments[1].text}</h6>
                              <img
                                src={segments[1].gift_image}
                                alt={segments[1].text}
                              />
                            </i>
                          </span>
                          <span className="segment span4 color-2">
                            <i
                              id="id_6"
                              className="item6 segments font-bold-mon"
                            >
                              <h6>{segments[5].text}</h6>
                              <img
                                src={segments[5].gift_image}
                                alt={segments[5].text}
                              />
                            </i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="px-5">
                    <button
                      className="btn-luotquay relative"
                      onClick={() => {
                        runWheel();
                      }}
                      disabled={isSpinning ? "disabled" : ""}
                    >
                      <span className="font-semibold-mon text-[16px] text-[#16499F]">
                        {dataCallUrbox >= 1
                          ? `Bạn còn ${dataCallUrbox} lượt quay`
                          : "Bạn đã hết lượt quay"}
                      </span>
                    </button>
                  </div>
                </>
              </Container>
              <FooterHasNavbar />
            </div>
          )}
        </div>
      ) : (
        <div></div>
      )}
      {openPopup ? (
        <ConfirmPopupBtnHorizontal
          isShow={openPopup}
          labelOk="Đồng ý"
          message={"Bạn đã hết lượt quay"}
          handleOk={handlePopupErrorOkImg}
        />
      ) : null}
    </>
  );
};

export default Home;
