import Logo from "../../assets/fontawesome/image/logo_mvc.png";
import Gach from "../../assets/fontawesome/image/gach.png";
export default function FooterHasNavbar() {
  const handleMVC = () => {
    window.open("https://mvc.com.vn");
  };
  return (
    <>
      <div
        onClick={handleMVC}
        className="cursor-pointer z-50 fixed bottom-0 w-full flex justify-center items-center h-[25px] font-normal text-[13px] leading-5 text-[#333333] rounded-t-2xl bg-white"
      >
        <img src={Logo} className="form__footer-logo" />
        <div className="form__footer-copyright ml-1 text-right">
          Developed by <span className="form__footer-mvc">MVC Company.</span>
        </div>
      </div>
    </>
  );
}
