import { formatDateString } from "../../services/localService/utils";

export default function ParticipateHisItem({ lstProduct }) {
  return (
    <>
      <img
        src={lstProduct?.campaign_image}
        className="containerNotify__background-itemImage w-[48px] h-[48px] rounded-lg"
      />
      <div className="containerNotify__background-itemChild pl-2.5 box-border">
        <div className="containerNotify__background-itemChildName content-notify  ">
          {lstProduct?.store_name}
        </div>
        <div className="content-description-his content-notify corlor-text-darkblue leading-[18px] text-[#0AAA52]">
          {lstProduct?.campaign_name}
        </div>
        <div className="containerNotify__background-itemChildTime text-[#BDBDBD]">
          {lstProduct?.participation_date}
        </div>
      </div>
      <div className="text-right flex flex-col justify-between ml-auto">
        <div className="period-parhis text-[#0A85D1]">
          {formatDateString(lstProduct?.start_date)} {" - "}
          {formatDateString(lstProduct?.end_date)}{" "}
        </div>
        <div className="quantity-parhis corlor-text-primarygreen">
          X{lstProduct?.gif_no} lượt chơi
        </div>
      </div>
    </>
  );
}
