import moment from "moment";

export const formatDate = (date = "") => {
  return moment(date).format("DD/MM/YY");
};

export const formatDateString = (inputDateString) => {
  // Parse the input date string
  var parts = inputDateString?.split("/");
  var day = parts[0];
  var month = parts[1];
  var year = parts[2];

  // Create a Date object
  var dateObject = new Date(year, month - 1, day);

  // Get the components of the date
  var formattedDay = dateObject.getDate();
  var formattedMonth = dateObject.getMonth() + 1;
  var formattedYear = dateObject.getFullYear().toString().slice(-2);

  // Add leading zeros if needed
  formattedDay = formattedDay < 10 ? "0" + formattedDay : formattedDay;
  formattedMonth = formattedMonth < 10 ? "0" + formattedMonth : formattedMonth;

  // Concatenate the formatted components
  var formattedDate = formattedDay + "/" + formattedMonth + "/" + formattedYear;

  return formattedDate;
};
