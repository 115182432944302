import Modal from "react-modal";
import check_green from "../../assets/fontawesome/image/check-green.png";
import GiftItem from "./GiftItem";
import phone_icon from "../../assets/fontawesome/image/phone-icon.png";
import play_icon from "../../assets/fontawesome/image/play-icon.png";
import { useForm } from "react-hook-form";
import { userServices } from "../../services/apiService/userServices";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import headerPopup from "../../assets/fontawesome/image/header_popup.png";
import { userDataLocal } from "../../services/localService/localService";
import { setAuthorization } from "../../services/apiService/configURL";
import { useSelector } from "react-redux";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "none",
    borderRadius: "32px",
  },
};
export default function ModalGift({ modalIsOpen, soIds, listPrize }) {
  // let { token } = useSelector((state) => {
  //   return state.userReducer.userData;
  // });
  let contact = JSON.parse(localStorage.getItem("CAMPAIGN_INFO"));

  const navigate = useNavigate();

  const handleOnSubmit = () => {
    const name = localStorage.getItem("nameStaff");
    console.log("namestaff Qua: ", name);
    let info = { so_ids: soIds, consultant: name };
    // toast.success("Xác nhận thành công", name);
    userServices
      .postUpdateConsultant(info)
      .then((res) => {
        navigate("/wilmar/list-rotation");
        // setAuthorization(token)
        // toast.success("Xác nhận thành công");
      })
      .catch((err) => {
        toast.error("Đã xảy ra lỗi. Vui lòng thử lại sau");
      });
  };
  return (
    <div className="">
      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        contentLabel="Gift Modal"
        ariaHideApp={false}
      >
        {/* <form
          className="w-[370px] rounded-[32px]"
        onSubmit={handleSubmit(onSubmit)}
        > */}
        <div className="w-[370px] rounded-[32px]">
          <div className="guide-take-photo modal-header rounded-[32px] relative flex-col justify-center items-center">
            <img src={headerPopup} />
            <div className="modal-body bg-[#FFFFFF] rounded-[32px] w-full ">
              <div className="check">
                <img className="" src={check_green} alt="" />
              </div>
              <div className="w-full px-5 flex flex-col justify-center items-center">
                <div className="text-center ">
                  <h1 className="uppercase text-[#003DA5] text-[24px]">
                    thông báo
                  </h1>
                  <p className="font-light-mon text-[12px]">
                    Hóa đơn của bạn hợp lệ <br />
                    bạn nhận được các phần thưởng sau
                  </p>
                </div>
                <div className="w-full max-h-[360px] overflow-scroll mx-4 my-4">
                  <ul className="containerNotify__background-list box-border  z-30 flex justify-center flex-col items-center ">
                    {listPrize.map((infor, index) => {
                      return (
                        <li
                          key={index}
                          className="px-[10] pt-[12px] bg-[#f0f0f0] w-[290px] rounded-xl mb-4 overflow-hidden  "
                        >
                          <GiftItem infor={infor} />
                        </li>
                      );
                    })}
                  </ul>
                </div>

                <div className="flex justify-center gap-1 mt-[25px]">
                  <a
                    href={"tel:"}
                    className="bg-[#3B91C6] hotline-btn text-[#fff] flex
                         justify-center items-center rounded-[16px] px-[14px] py-[12px] gap-1 w-24"
                  >
                    <img src={phone_icon} alt="" />
                    <span className="font-semibold-mon text-[13px]">
                      Hotline
                    </span>
                  </a>
                  <button
                    className="bg-[#003DA5] hotline-btn text-[#fff] flex
                        justify-center items-center rounded-[16px] px-[14px] py-[12px] gap-1 w-48"
                    onClick={handleOnSubmit}
                  >
                    <img src={play_icon} alt="" />
                    <span className="font-semibold-mon text-[13px]">
                      Xác nhận đủ quà hoặc lượt quay
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </form> */}
      </Modal>
    </div>
  );
}
