
import React from 'react'
import '../../assets/css/backgroundNotifyGift.css';
import Image from '../../assets/fontawesome/image/icon_noti.svg';
const styleButton = {
    color: '#333',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '20px',
}
const styleButtonOk = {
    color: '#fff',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '20px',
}
export default function ConfirmPopupLogin({
    image,
    btnLater,
    btnAgree,
    titlePopup,
    onClosePopup = () => { },
    handleCancel,
    handleOk }) {
    const handleClickCancel = () => {
        onClosePopup()
        handleCancel()
    }
    const handleClickOk = () => {
        handleOk()
    }
    return (

        <div className='z-50'>
            <label htmlFor='check' className=' fixed inset-0 block opacity-50 z-50'></label>
            <div className='conatiner__notify fixed bg-[#fff] bottom-0 left-0 h-[178px] w-full rounded-t-[20px] font-[Montserrat] z-50'>
                <img src={image ? image : Image} defaultValue={Image} className="image__gift absolute top-0 left-1/2 z-50" />
                <div className=' conatiner__notify-name mx-8 mt-12 text-[black] text-center max-[325px]:text-[10px]'>
                    {titlePopup}
                </div>

                <div className=' flex justify-center mt-2.5 items-center'>
                    <input style={styleButton} type="button" className="conatiner__notify-btnClose flex justify-center items-center align-middle format-button-popup bg-[#47ccff]" defaultValue='Để sau' value={btnLater} onClick={handleClickCancel} />
                    <input style={styleButtonOk} type="button" className="conatiner__notify-btnOK bg-[#003da5] flex justify-center format-button-popup" defaultValue='Đồng ý' value={btnAgree} onClick={handleClickOk} />
                </div>
            </div>
        </div>
    )
}
