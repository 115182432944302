import "../../assets/css/backgroundListNotify.css";
import "../../assets/css/backgroundPhone.css";
import "../../assets/css/listRotation.css";
import "../../assets/css/font-text.css";
import IconPhoneAndZalo from "../../component/IconPhoneAndZalo/IconPhoneAndZalo";
import WARNING from "../../assets/fontawesome/image/warning.png";
import { useEffect, useState } from "react";
import { luckyDrawService } from "../../services/apiService/LuckyDraw";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import BACKGROUND_CONTENT from "../../assets/fontawesome/image/background-content.png";
import { userDataLocal } from "../../services/localService/localService";
import { setAuthorization } from "../../services/apiService/configURL";
import FooterHasNavbar from "../../component/Footer/FooterHasNavbar";
import NavbarHome from "../../component/NavbarHome/NavbarHome";
import Loading from "../../component/Loading/Loading";
const TITLE = "Danh sách vòng quay";

export default function ListRotation() {
  const { token } = userDataLocal.get();
  console.log(token);
  const [luckyDrawList, setLuckyDrawList] = useState([]);
  const [status, setStaus] = useState(false);
  const navigation = useNavigate();
  useEffect(() => {
    setStaus(true);
    setAuthorization(token);
    luckyDrawService
      .getLuckyDrawList()
      .then((res) => {
        console.log(res);
        const resp = res.lucky_draw_list.filter((x) => x.campaign_id === 109);
        setLuckyDrawList(resp);
        setStaus(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const handleToarstErr = () => {
    toast.error("Không có vòng quay");
  };
  const handleRotation = (pg_so_code) => {
    navigation("/wilmar/wheel/" + pg_so_code);
  };

  return (
    <div className="contain">
      {status ? <Loading /> : null}
      <div>
        <img src={BACKGROUND_CONTENT} className="fixed" />
      </div>
      <div className=" max-h-full absolute rounded-[30px_30px_0_0] h-[86%] w-full z-10">
        {luckyDrawList?.length === 0 ? (
          <div className="relative">
            <div className="absolute top-40 left-1/2 -translate-x-1/2">
              <img src={WARNING} />
              <div className="text-center font-semibold-mon text-black mt-5">
                Hiện tại, bạn vẫn chưa có lượt chơi. Tham gia ngay để nhận ngay
                cơ hội săn quà siêu hấp dẫn.
              </div>
            </div>
          </div>
        ) : (
          <ul className="containerNotify__background-list pt-8 box-border z-20">
            {luckyDrawList?.map((item) => (
              <>
                {item.remaining_draw > 0 ? (
                  <li
                    key={item.pg_so_code}
                    className={`mx-[25px] py-3 relative rounded-2xl flex items-center bg-[#F0F0F0] mb-4 overflow-hidden ${
                      item.remaining_draw > 0 ? "" : "filter grayscale"
                    }`}
                    onClick={() => handleRotation(item.pg_so_code)}
                  >
                    <div className="w-full flex relative">
                      <div className="flex">
                        <div className=" px-[12px] flex justify-center">
                          <img
                            src={item.image}
                            className="image-product w-[100px] h-[100px] text-center rounded-xl"
                            alt=""
                          />
                          <div className="flex flex-col justify-between pl-[9px]">
                            <div className=" text-[13px] leading-[15px] font-semibold-mon">
                              <div className="text-reponsive -mb-3">
                                {" "}
                                {item.title}
                              </div>
                              <br />
                              {item.remaining_draw > 0 ? (
                                <div className="font-regular-mon">
                                  Bạn có{" "}
                                  <span className="text-red-600 font-semibold-mon">
                                    {item.remaining_draw}
                                  </span>{" "}
                                  vòng quay
                                </div>
                              ) : (
                                <div
                                  onClick={() => {
                                    handleToarstErr();
                                  }}
                                  className=" text-reponsive font-regular-mon leading-[20px]  text-[#828282] "
                                >
                                  Bạn có{" "}
                                  <span className="text-red-600">
                                    {item.remaining_draw}
                                  </span>{" "}
                                  vòng quay
                                </div>
                              )}
                            </div>
                            <div className="text-reponsive-date text-[#828282]">
                              Ngày hết hạn quay thưởng{" "}
                              <span className="text-[#1D86FA]">
                                {item.expired_date}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="absolute -bottom-0 right-0 h-full w-full justify-end flex items-end">
                      {item.remaining_draw > 0 ? (
                        <NavLink
                          to={`/wilmar/wheel/${item.pg_so_code}`}
                          className="bgr-icon-back flex background-bottom-icon w-14 h-14 z-30"
                        >
                          <div className="icon-bottom w-6 h-6 z-40 mt-5 ml-5"></div>
                        </NavLink>
                      ) : (
                        <div
                          onClick={() => {
                            handleToarstErr();
                          }}
                          className="bgr-icon-back flex background-bottom-icon w-14 h-14 z-30"
                        >
                          <div className="icon-bottom w-6 h-6 z-40 mt-5 ml-5"></div>
                        </div>
                      )}
                    </div>
                  </li>
                ) : (
                  <li
                    key={item.pg_so_code}
                    className={`mx-[25px] relative py-3 rounded-2xl flex items-center bg-[#F0F0F0] mb-4 overflow-hidden ${
                      item.remaining_draw > 0 ? "" : "filter grayscale"
                    }`}
                    onClick={() => {
                      handleToarstErr();
                    }}
                    // onClick={() => handleRotation(item.pg_so_code)}
                  >
                    <div className="w-full flex">
                      <div className="flex">
                        <div className=" px-[12px] flex justify-center">
                          <img
                            src={item.image}
                            className="image-product w-[100px] h-[100px] text-center rounded-xl"
                            alt=""
                          />
                          <div className="flex flex-col justify-between pl-[9px]">
                            <div className=" text-[13px] leading-[18px] font-semibold-mon">
                              <div className="text-reponsive -mb-3">
                                {" "}
                                {item.title}
                              </div>
                              <br />
                              {item.remaining_draw > 0 ? (
                                <>
                                  Bạn có{" "}
                                  <span className="text-red-600">
                                    {item.remaining_draw}
                                  </span>{" "}
                                  vòng quay
                                </>
                              ) : (
                                <div
                                  // onClick={() => {
                                  //   handleToarstErr();
                                  // }}
                                  className=" text-reponsive font-regular-mon leading-[20px]  text-[#828282] "
                                >
                                  Bạn có{" "}
                                  <span className="text-red-600">
                                    {item.remaining_draw}
                                  </span>{" "}
                                  vòng quay
                                </div>
                              )}
                            </div>
                            <div className="text-reponsive-date text-[#828282]">
                              Ngày hết hạn quay thưởng{" "}
                              <span className="text-[#1D86FA]">
                                {item.expired_date}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="absolute right-0 flex flex-col justify-end items-end align-bottom bottom-0 h-full ">
                      {item.remaining_draw > 0 ? (
                        // <NavLink
                        //   to={`/wilmar/wheel/${item.pg_so_code}`}
                        //   className=" bgr-icon-back flex background-bottom-icon w-14 h-14 z-30"
                        // >
                        <div className="icon-bottom w-6 h-6 z-40 mt-5 ml-5"></div>
                      ) : (
                        // </NavLink>
                        <div
                          onClick={() => {
                            // handleToarstErr();
                          }}
                          className=" bgr-icon-back flex background-bottom-icon w-14 h-14 z-30"
                        >
                          <div className="icon-bottom w-6 h-6 z-40 mt-5 ml-5"></div>
                        </div>
                      )}
                    </div>
                  </li>
                )}
              </>
            ))}
          </ul>
        )}
        <div className="h-40"></div>
      </div>
      <FooterHasNavbar />
      <IconPhoneAndZalo />
      <NavbarHome />
    </div>
  );
}
