import "../../assets/css/Login.css";
import React, { useState } from "react";
import "../../assets/fontawesome/css/all.min.css";
import "../../assets/css/background.css";
import "../../assets/css/backgroundHeader.css";
import "../../assets/css/backgroundButton.css";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import "../../assets/css/font-text.css";
import ConfirmPopupPhone from "../../component/ConfirmPopupPhone/ConfirmPopupPhone";
import { toast } from "react-toastify";
import SubmitReceipt from "../../component/SubmitReceipt/SubmitReceipt";

const style = {
  color: "#130682",
  fontSize: "16px",
  fontHeight: "20px",
  fontWeight: "600",
};
const styleButton = {
  color: "#333",
  fontWeight: "600",
  fontSize: "16px",
  lineHeight: "20px",
};
function LoginNoRegister() {
  document.body.style.backgroundColor = "black";
  const [triggerSubmitReceipt, setTriggerSubmitReceipt] = useState(false);
  const navigation = useNavigate();
  const [popup, setPopup] = useState(false);
  let gcsResult = JSON.parse(localStorage.getItem("GCS_RESULT"));

  const [checkAgree, setCheckAgree] = useState(false);
  const handleClickCheck = (e) => {
    setCheckAgree(!checkAgree);
  };

  const updateInfoDone = (res) => {
    setTriggerSubmitReceipt(true);
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    criteriaMode: "all",
  });

  const onSubmit = (data) => {
    if (gcsResult) {
      setPopup(true);
      localStorage.setItem("phoneData", data.phone);
      localStorage.setItem("nameData", data.name);
    } else {
      toast.error("Vui lòng chờ tải dữ liệu ảnh!");
    }
  };

  const handleBack = () => {
    navigation(-1);
  };
  return (
    <div className="relative">
      <div className="container relative bg_default text-[#fff] w-screen min-w-full h-full min-h-screen px-[25px] flex flex-col box-border">
        <div className="icon-back mt-[7%] py-[36px] h-8 flex items-center opacity-100 max-w-full w-full z-50">
          <i
            className="fa-solid fa-chevron-left fa-solid-back"
            onClick={handleBack}
          ></i>
        </div>
        <header className="p-0 w-52 h-9 leading-9 text-2xl not-italic ">
          Đăng nhập
        </header>
        <div className="container__login-item p-[16px_0_68px_0] text-[#fff] text-base font-light-mon w-[87%]">
          Nhập số điện thoại của bạn để nhận phần thưởng
        </div>
        <div className="container__login-form">
          <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <div className="form__login z-10">
              <div className="flex flex-nowrap h-[70px] rounded-[14px] mt-0  bg-[#fff] relative z-0 border-[#98EBFF] border-[1px]">
                <input
                  className="form__phone  text-[15px] box-border flex-[6]  pl-4 h-full z-10 text-black font-['Montserrat-Regular'] rounded-r-xl rounded-l-xl"
                  placeholder="Nhập tên của bạn "
                  {...register("name", {
                    required: "Không được để trống",
                    pattern: {
                      value: /^[\D*]{1,}$/,
                      message: "Vui lòng chỉ nhập kí tự",
                    },
                  })}
                />
              </div>
              <div className="font-normal z-0 font-[Montserrat-Regular] text-[red] text-[13px] text-center">
                <ErrorMessage
                  errors={errors}
                  name="name"
                  render={({ messages }) => {
                    console.log("messages", messages);
                    return messages
                      ? Object.entries(messages).map(([type, message]) => (
                          <p
                            key={type}
                            className="bg-[#EEE6E7] pt-2.5 -mt-2.5 z-0 rounded-bl-xl rounded-br-xl border-[#F63440] border-[1px]"
                          >
                            {message}
                          </p>
                        ))
                      : null;
                  }}
                />
              </div>

              <div className="flex flex-nowrap h-[70px] rounded-[14px] mt-7  bg-[#fff] relative z-0 border-[#98EBFF] border-[1px]">
                {/* <!-- <input className="form__name" type="text" placeholder="Nhập tên của bạn"/> --> */}
                <div className="icon z-20 flex justify-center items-center text-[13px] ">
                  <i className="fa-solid fa-mobile-screen-button fa-solid-phone"></i>
                  {/* <img src="https://product.hstatic.net/200000122283/product/c-e1-bb-9d-vi-e1-bb-87t-nam_2c0683597d2d419fac401f51ccbae779_grande.jpg" className="icon__flag" /> <p>+84</p> */}
                </div>
                <input
                  className="form__phone m-[0_0_0_5px] text-[15px] box-border flex-[6] w-52 pl-2.5 h-full z-10 text-black font-['Montserrat-Regular'] rounded-r-xl"
                  placeholder="Nhập số điện thoại "
                  type="tel"
                  {...register("phone", {
                    required: "Không được để trống",
                    pattern: {
                      value: /(84|0[3|5|7|8|9])+([0-9]{8})\b/,
                      message: "Vui lòng nhập số",
                    },
                  })}
                />
              </div>

              <div className="font-normal z-0 font-[Montserrat-Regular] text-[red] text-[13px] text-center">
                <ErrorMessage
                  errors={errors}
                  name="phone"
                  render={({ messages }) => {
                    console.log("messages", messages);
                    return messages
                      ? Object.entries(messages).map(([type, message]) => (
                          <p
                            key={type}
                            className="bg-[#EEE6E7] pt-2.5 -mt-2.5 z-0 rounded-bl-xl rounded-br-xl border-[#F63440] border-[1px]"
                          >
                            {message}
                          </p>
                        ))
                      : null;
                  }}
                />
              </div>
              <div className="m-[46px_0_28px_0] flex font-[Montserrat-Light]">
                <div className="flex items-center mb-4">
                  <input
                    id="default-checkbox"
                    type="checkbox"
                    onClick={(e) => handleClickCheck(e)}
                    value=""
                    className="checkbox-confirm w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                </div>
                <label
                  htmlFor="check"
                  className="text-[#fff] mr-[10px]  font-normal text-[13px] leading-[20px]"
                >
                  {" "}
                </label>
                <label
                  htmlFor="check"
                  className="text-[#fff] mr-[10px] font-regular-mon font-normal text-[13px] leading-[20px]"
                >
                  Tôi đồng ý với các Điều khoản & điều kiện của chương trình
                  <span>
                    {" "}
                    <a
                      target="_blank"
                      href="/tc.html"
                      className="form__condition"
                    >
                      {" "}
                      Xem chi tiết
                    </a>
                  </span>
                </label>
              </div>
              {checkAgree === true ? (
                <>
                  <div className="text-[12px] mt-[5px] font-light-mon text-[#fff] leading-5 whitespace-pre-line flex flex-wrap break-words">
                    Bằng việc cung cấp thông tin và chọn Tiếp Tục, tôi đồng ý
                    cho P&G và bên cung cấp dịch vụ được P&G ủy quyền được sử
                    dụng, quản lý các thông tin cá nhân do tôi cung cấp vào mục
                    đích thống kê, kiểm tra việc thực hiện chương trình do P&G
                    hỗ trợ ngân sách, theo đó P&G và đơn vị dịch vụ được ủy
                    quyền sẽ lưu trữ các thông tin cá nhân trong thời hạn 5 năm.
                    Để được tiếp cận, cập nhật, điều chỉnh thông tin cá nhân,
                    tôi sẽ liên hệ P&G theo số điện thoại (+84) 3521 4545 hoặc
                    theo địa chỉ: Tầng 11 Mplaza Saigon 39 Lê Duẩn, Quận 1,
                    TP.HCM. Chi tiết chính sách của P&G về quản lý thông tin
                    riêng tư tại
                    <a
                      href="http://ww.pg.com/privacy/english/privacy.notice.shtml"
                      className="break-all mt-0 no-underline text-[#3599e9]"
                    >
                      http://ww.pg.com/privacy/english/privacy_notice.shtml
                    </a>
                  </div>

                  <input
                    type="checkbox"
                    hidden
                    name=""
                    className="nav__check"
                    id="btn__checked"
                  />

                  <label
                    htmlFor="btn__checked"
                    className="  flex justify-center py-[56px] box-border text-[#333] font-light-mon"
                  >
                    <input
                      style={style}
                      type="submit"
                      htmlFor="btn__checked"
                      className="color-button-enable border-0 button-login font-black leading-5"
                      value="Đăng kí tham gia"
                    />
                  </label>
                  <label
                    htmlFor="btn__checked"
                    className="nav__overlay"
                  ></label>
                </>
              ) : (
                <div className="flex justify-center py-[56px] box-border text-[#333] font-light-mon">
                  <input
                    style={styleButton}
                    type="button"
                    className="color-button-disable border-0 button-login not-italic font-[Montserrat-Light] font-black leading-5"
                    value="Đăng kí tham gia"
                  />
                </div>
              )}
            </div>
          </form>
        </div>
        {/* </div> */}
      </div>
      <SubmitReceipt trigger={triggerSubmitReceipt}></SubmitReceipt>
      {popup ? (
        <>
          <ConfirmPopupPhone
            setPopup={setPopup}
            popup={popup}
            updateInfoDone={updateInfoDone}
          />
        </>
      ) : null}
    </div>
  );
}

export default LoginNoRegister;
