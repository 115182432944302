import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { userDataLocal } from "../../services/localService/localService";
import { userServices } from "../../services/apiService/userServices";
import "../../assets/css/background.css";
import "../../assets/css/backgroundHeader.css";
import "../../assets/css/Login.css";
import "../../assets/css/Register.css";
import "../../assets/css/background__Footer.css";
import "../../assets/css/font-text.css";
import { addDays } from "date-fns";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import SubmitReceipt from "../SubmitReceipt/SubmitReceipt";
import { useSelector } from "react-redux";
import moment from "moment";
import { provinceLocal } from "../../services/localService/localService";
import { setAuthorization } from "../../services/apiService/configURL";
const style = {
  // backgroundColor: '#47CCFF',
  // color: '#130682',
  // fontSize: '16px',
  // fontHeight: '20px',
  // fontWeight: '400',
  width: "100%",
  backgroundColor: "#EEF5FC",
};

export default function RegisterComponent({ updateInfo }) {
  const navigation = useNavigate();
  let gcsResult = JSON.parse(localStorage.getItem("GCS_RESULT"));
  let { token } = userDataLocal.get();
  let dataUser = userDataLocal.get();
  console.log(dataUser);
  const nameData = localStorage.getItem("nameData");
  const [triggerSubmitReceipt, setTriggerSubmitReceipt] = useState(false);

  const revertDate = (value) => {
    // var newdate = value
    //   ?.replace(/^(\d{1,2}\/)(\d{1,2}\/)(\d{4})$/, "$2$1$3")
    //   ?.split("/")
    //   ?.join(" ");
    var myDate = value.split("/");
    var newDate = new Date(myDate[2], myDate[1] - 1, myDate[0]);
    console.log(newDate.getTime());
    return newDate.getTime();
  };
  const [userInfo, setUserInfo] = useState({});
  const [provinceData, setProvinceData] = useState([]);
  const [districData, setDistricData] = useState([]);
  const [defaultDistrict, setDefaultDistrict] = useState([]);

  const [customerName, setCustomerName] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [provinceId, setProvinceId] = useState(0);
  const [districtID, setDistricID] = useState(0);
  const [checkStatusBirth, setCheckStatusBirth] = useState(false);
  const [customerBirthday, setCustomerBirthday] = useState(
    dataUser?.birthday === "" ? null : new Date(revertDate(dataUser?.birthday))
  );

  console.log(customerBirthday);
  const [customerSex, setCustomerSex] = useState("");
  useEffect(() => {
    if (userInfo) {
      setCustomerName(nameData);
      setCustomerEmail(userInfo.email);

      setCustomerSex(userInfo.gender);
    }
  }, [userInfo]);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    criteriaMode: "all",
  });
  const onSubmit = (value) => {
    const data = {
      pnj_customer_id: dataUser.pnj_customer_id,
      name: customerName,
      email: customerEmail,
      province_id: +provinceId,
      district_id: +districtID,
      gender: customerSex,
      birthday: moment(customerBirthday).format("DD/MM/YYYY"),
    };
    userServices
      .postUserInfo(data)
      .then((res) => {
        console.log(res);
        setAuthorization(token);
        const dataToken = {
          pnj_customer_id: dataUser.pnj_customer_id,
          token: token,
        };
        localStorage.setItem("nameData", data.name);
        const finalResult = Object.assign(dataToken, res);
        console.log(finalResult);
        userDataLocal.set(finalResult);
        // toast.success("Cập nhật thành công!");
        if (gcsResult) {
          setTriggerSubmitReceipt(true);
        } else {
          setTimeout(() => {
            navigation("/wilmar");
          }, 1000);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  useEffect(() => {
    setCustomerName(nameData);
    setCustomerEmail(dataUser?.email);
    setUserInfo(dataUser);
    setProvinceData(dataUser.province_list);
    setDistricData(dataUser.district_list);
    setDefaultDistrict(dataUser?.district_list);
    setProvinceId(dataUser.province_id);
    setDistricID(dataUser.district_id);
    if (getAge(customerBirthday) >= 18) {
      setCheckStatusBirth(true);
    }
  }, []);

  const handleProvinceChange = (value) => {
    setProvinceId(value.target.value);
  };
  const onChangeSex = (e) => {
    setCustomerSex(e.target.value);
  };
  const handleOnChangeDistrict = (value) => {
    setDistricID(value.target.value);
  };
  // const found = paragraph.match(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/);
  const handleChangeEmail = (value) => {
    setCustomerEmail(value.target.value);
  };

  function getAge(dateString) {
    console.log(dateString);
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    console.log(age);
    if (age >= 18) {
      console.log("Được phép truy cập");
      setCheckStatusBirth(true);
      setCustomerBirthday(dateString);
    } else {
      setCustomerBirthday(dateString);
      toast.error("Vui lòng chọn ngày sinh từ 18 tuổi trở lên");
      setCheckStatusBirth(false);
    }
    return age;
  }
  console.log(checkStatusBirth);
  const handleChangeBirthday = (date) => {
    console.log(date);
    getAge(date);
  };
  const [checkAgree, setCheckAgree] = useState(false);
  const handleClickCheck = (e) => {
    console.log(e);
    if (checkStatusBirth) {
      console.log("sinh ddungs");
      setCheckAgree(!checkAgree);
    } else {
      toast.error("Vui lòng chọn ngày sinh từ 18 tuổi trở lên");
      setCheckAgree(false);
    }
  };
  const handleOnchangeName = (event) => {
    setCustomerName(event.target.value);
  };
  const filterDistrictByProvinceID = (id) => {
    let cloneList = [...defaultDistrict];
    cloneList = cloneList.filter((province) => {
      return id === province.province_id;
    });

    setDistricData(cloneList);
  };
  useEffect(() => {
    filterDistrictByProvinceID(+provinceId);
  }, [provinceId, userInfo]);
  return (
    <>
      <form className="form_register" onSubmit={handleSubmit(onSubmit)}>
        <input
          className="form__name input-size input-data "
          placeholder="Nhập tên của bạn"
          {...register("name")}
          style={style}
          value={customerName || ""}
          onChange={(e) => {
            handleOnchangeName(e);
          }}
        />
        <input
          className="form__phoneField input-size input-data bg-[#bdbdbd] my-10"
          placeholder="Số điện thoại "
          readOnly
          {...register("phone")}
          value={userInfo?.phone || ""}
          style={{
            color: "#333333",
            fontSize: "16px",
            fontHeight: "20px",
            fontWeight: "400",
          }}
        />
        <input
          className="form__email input-size font-normal input-data "
          placeholder="Nhập email của bạn "
          {...register("email")}
          onChange={(e) => {
            handleChangeEmail(e);
          }}
          value={customerEmail || ""}
          style={style}
        />
        <select
          className="select-wrapper select_city my-10  input-size pr-6 font-normal input-data"
          onChange={(e) => handleProvinceChange(e)}
          style={style}
          value={provinceId || ""}
        >
          <option value="-1" disabled defaultValue className="pr-5">
            Nhập tỉnh/thành phố của bạn
          </option>
          {provinceData?.map((province) => (
            <option value={province.id} key={province.id}>
              {province.name}
            </option>
          ))}
        </select>
        <select
          className="select-wrapper select_province input-size font-normal  mb-10 input-data"
          onChange={(e) => {
            handleOnChangeDistrict(e);
          }}
          value={districtID || ""}
          style={style}
        >
          <option value="-1" style={{ color: "#BDBDBD" }} disabled>
            Nhập Quận/Huyện của bạn
          </option>

          {districData?.map((district) => (
            <option value={district.id} key={district.id}>
              {district.name}
            </option>
          ))}
        </select>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "calc(100%)",
          }}
        >
          <DatePicker
            className="datePicker corlor-text-darkblack font-regular-mon h-[60px] rounded-[15px] pl-5 text-[13px] w-full bg-[#EEF5FC]"
            placeholderText="Ngày sinh"
            selected={customerBirthday ? customerBirthday : null}
            onChange={(date) => {
              handleChangeBirthday(date);
            }}
            dateFormat="dd/MM/yyyy"
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            disabledKeyboardNavigation
            dropdownMode="select"
            maxDate={addDays(new Date(), -1)}
            onKeyDown={(e) => {
              e.preventDefault();
              return false;
            }}
            onFocus={(e) => e.target.blur()}
          />
          <select
            className="form__sex font-regular-mon h-[60px] w-full select-wrapper "
            onChange={(e) => onChangeSex(e)}
            style={{
              fontSize: "13px",
              width: "50%",
              color: "#757575",
              borderRadius: "15px",
              borderColor: "white",
              marginLeft: "15px",
              paddingLeft: "20px",

              backgroundColor: "#EEF5FC",
            }}
            value={customerSex || ""}
          >
            <option value="Giới tính" key="sex" disabled defaultValue>
              Giới tính
            </option>
            <option value="male" key="male">
              Nam
            </option>
            <option value="female" key="female">
              Nữ
            </option>
          </select>
        </div>

        <div className="m-[46px_0_28px_0] flex font-light-mon">
          {checkStatusBirth === false ? (
            <input
              id="default-checkbox"
              type="checkbox"
              disabled={true}
              className="checkbox-confirm-register w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
          ) : (
            <div>
              <input
                id="default-checkbox"
                type="checkbox"
                onClick={(e) => handleClickCheck(e)}
                className="checkbox-confirm-register w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
            </div>
          )}
          <label
            htmlFor="check"
            className="text-[#333333] mr-[10px] font-[Montserrat] not-italic font-normal text-[13px] leading-[20px]"
          >
            {" "}
          </label>
          <label
            htmlFor="check"
            // className=" text-[#333333] mr-[10px] font-regular-mon not-italic font-normal text-[13px] leading-[20px]"
            className={`${
              updateInfo ? "corlor-text-darkblack" : "corlor-text-white"
            } mr-[10px] font-regular-mon not-italic font-normal text-[13px] leading-[20px]`}
          >
            Tôi đồng ý với các Điều khoản & điều kiện của chương trình
            <span>
              {" "}
              <a target="_blank" href="/tc.html" className="form__condition">
                {" "}
                Xem chi tiết
              </a>
            </span>
          </label>
        </div>
        {checkAgree === true ? (
          <>
            <div
              // className="text-[12px] mt-[5px] font-regular-mon text-[#333333] leading-5 whitespace-pre-line flex flex-wrap break-words"
              className={`${
                updateInfo ? "corlor-text-darkblack" : "corlor-text-white"
              } text-[12px] mt-[5px] font-regular-mon leading-5 whitespace-pre-line flex flex-wrap break-words`}
            >
              Bằng việc cung cấp thông tin và chọn Tiếp Tục, tôi đồng ý cho P&G
              và bên cung cấp dịch vụ được P&G ủy quyền được sử dụng, quản lý
              các thông tin cá nhân do tôi cung cấp vào mục đích thống kê, kiểm
              tra việc thực hiện chương trình do P&G hỗ trợ ngân sách, theo đó
              P&G và đơn vị dịch vụ được ủy quyền sẽ lưu trữ các thông tin cá
              nhân trong thời hạn 5 năm. Để được tiếp cận, cập nhật, điều chỉnh
              thông tin cá nhân, tôi sẽ liên hệ P&G theo số điện thoại (+84)
              3521 4545 hoặc theo địa chỉ: Tầng 11 Mplaza Saigon 39 Lê Duẩn,
              Quận 1, TP.HCM. Chi tiết chính sách của P&G về quản lý thông tin
              riêng tư tại
              <a
                href="http://www.pg.com/privacy/english/privacy_notice.shtml"
                className="break-all mt-0 no-underline text-[#3599e9]"
                target="_blank"
              >
                http://www.pg.com/privacy/english/privacy_notice.shtml
              </a>
            </div>
          </>
        ) : (
          ""
        )}
        {checkAgree ? (
          <div className="flex justify-center py-[56px] box-border text-[#333] font-light-mon">
            <input
              type="submit"
              className="color-button-enable border-0 text-[#130682] px-[32px] py-[15px] text-center no-underline inline-block rounded-3xl text-[16px] cursor-pointer not-italic font-[Montserrat-Light] font-black leading-5"
              // value="Lưu lại thông tin"
              value={`${updateInfo ? "Lưu lại thông tin" : "Đăng nhập"}`}
            />
          </div>
        ) : (
          <div className="flex justify-center py-[56px] box-border text-[#333] font-light-mon">
            <input
              type="button"
              className="color-button-disable border-0 text-[#33333] px-[32px] py-[15px] text-center no-underline inline-block rounded-3xl text-[16px] cursor-pointer not-italic font-[Montserrat-Light] font-black leading-5"
              value={`${updateInfo ? "Lưu lại thông tin" : "Đăng nhập"}`}
            />
          </div>
        )}
      </form>
      <SubmitReceipt trigger={triggerSubmitReceipt}></SubmitReceipt>
    </>
  );
}
