import { useState } from "react";
import "../../assets/css/font-text.css";
import { QRCodeCanvas } from "qrcode.react";

export default function GiftItem({ infor }) {
  console.log(infor);
  const [status, setStatus] = useState(false);
  const handleToggle = () => {
    setStatus(!status);
  };
  function reformatDate(dateStr) {
    const date = dateStr.substring(0, dateStr.indexOf(" "));
    var dArr = date.split("-"); // ex input: "2010-01-18"
    console.log(dArr);
    return dArr[2] + "/" + dArr[1] + "/" + dArr[0]; //ex output: "18/01/10"
  }
  return (
    <>
      <div className="flex pt-[13px] pl-[14px]">
        <div className="flex items-center">
          <img src={infor.gift_image_url} alt="img" className="w-16 " />
        </div>
        <div className="ml-2">
          <div className="text-reponsive font-regular-mon text-[16px] leading-6">
            Bạn đã nhận được
          </div>
          <div className="text-item-detailgift text-[16px] ">
            {infor.gift_name}
          </div>
          {/* {infor.validated === true ? (
            <div className="item-status text-red-600">Đã đổi</div>
          ) : (
            <div className="item-status text-[#00AF43]">Chưa đổi</div>
          )} */}
        </div>
      </div>

      <div className="flex justify-between">
        {status === true ? (
          <div className="img-code flex justify-center ml-2 items-center mt-2 bg-white ">
            <QRCodeCanvas value={infor?.qrcode} size={80} />
          </div>
        ) : (
          <div className="font-text-13 font-regular-mon text-[#4F4F4F] pl-5 pb-2">
            Ngày hết hạn nhận quà{" "}
            <span className="text-[#0072B9] font-semibold-mon">
              {infor.expired_date}
            </span>
          </div>
        )}
      </div>
    </>
  );
}
