import BACKGROUND_CONTENT from "../../assets/fontawesome/image/background-content.png";

export default function DetectToken() {
  const handleClose = () => {
    window.flutter_inappwebview.callHandler("close_webview", null);
  };
  return (
    <div>
      <div className="relative">
        <img src={BACKGROUND_CONTENT} className="" />
        <div className="absolute -mt-40 detect-bg-white bg-white w-10/12 rounded-xl pb-5">
          <div className="flex justify-center items-center ">
            <div className="text-[20px] px-3 pt-5 font-regular-mon text-center">
              Hệ thống ghi nhận tài khoản của quý khách đã tham gia chương trình
              ở thiết bị khác. Quý khách vui lòng{" "}
              <span className="font-semibold-mon">tắt ứng dụng</span> và{" "}
              <span className="font-semibold-mon">mở lại</span>.
            </div>
          </div>
          <div className="flex justify-center">
            <button
              className="bg-[#0072B9] text-white px-5 py-2 rounded-lg mt-5"
              onClick={handleClose}
            >
              Thoát
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
